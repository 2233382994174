import React, { useState, useEffect, Fragment, useMemo } from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog"
import MmsService from 'Services/MMS/mms-api.service'
import { MenuItem } from '@material-ui/core'
import AlertToastr from 'utils/alert'
import { connect } from 'react-redux'
import useTranslation from 'Hooks/useTranslation'


const AssignPersonModal = ({ open, handleClose, employeeId, employees, currentDepartment, activityId, handleSave }) => {
    const translation = useTranslation();
    const emptyAssagneeOption = useMemo(() => {
        return { id: "empty", name: translation.assignPersonModalSelectAssigneeLabel }
    }, [translation])

    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null)
    const [disableButtons, setDisabledButtons] = useState(false)

    useEffect(() => {
        if (!open)
            return
        if (employeeId)
            setSelectedEmployeeId(employeeId)
        else
            setSelectedEmployeeId(emptyAssagneeOption.id)
    }, [employeeId, open, emptyAssagneeOption.id])

    const departmentEmployees = useMemo(() => {
        if (employees && currentDepartment)
            return employees.filter(e => e.departmentId === currentDepartment.id)
        else
            return null
    }, [employees, currentDepartment])

    const onSave = () => {
        setDisabledButtons(true)
        if (selectedEmployeeId === employeeId) {
            handleClose()
            setDisabledButtons(false)
            return
        }
        MmsService.assingPersonToActivity(activityId, selectedEmployeeId === 'empty' ? null : selectedEmployeeId).then((response) => {
            if (response.data.isSuccessful) {
                handleSave(selectedEmployeeId)
                AlertToastr.showAlert(translation.notificationsEmployeeAssigned)
            }
            handleClose()
            setDisabledButtons(false)
        })
            .catch((error) => {
                handleClose()
                setDisabledButtons(false)
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message)
            })
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                className='respModal scrollContentModal autoHeight'
            >
                <div className="formContentHolder">
                    <div className="modalTitleHolder">
                        <h1 className='modalTitle text-center'>{translation.assignPersonModalLabel}</h1>
                    </div>
                    <div className="formContent">
                        <div className="formContentBlock">
                            <TextField
                                value={selectedEmployeeId}
                                onChange={(e) => {
                                    e.persist()
                                    setSelectedEmployeeId(e.target.value)
                                }}
                                id="employees"
                                variant="outlined"
                                margin='normal'
                                select
                                fullWidth
                            >
                                <MenuItem key={emptyAssagneeOption.id} value={emptyAssagneeOption.id}>
                                    {emptyAssagneeOption.name}
                                </MenuItem>
                                {departmentEmployees && departmentEmployees.map((option) => {
                                    return <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                })}
                            </TextField>
                        </div>
                    </div>
                    <div className="btnHolder end fixedWidthBtn">
                        <div className="part">
                            <Button
                                disabled={!!disableButtons}
                                variant="outlined"
                                fullWidth
                                onClick={handleClose}
                            >
                                {translation.cancelButtonLabel}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                disabled={!!disableButtons}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={onSave}
                            >
                                {translation.saveButtonLabel}
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment >
    )
}
const mapStateToProps = ({ mms }) => ({
    employees: mms.employees,
    currentDepartment: mms.currentDepartment
})

export default connect(mapStateToProps, null)(AssignPersonModal)
