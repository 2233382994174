import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Dialog,
  TextField,
  FormLabel,
  MenuItem,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import { setRunComponentsUpdate } from "../../../../Store/modules/mms/actions";
import { connect } from "react-redux";
import useTranslation from "Hooks/useTranslation";
import MmsService from "Services/MMS/mms-api.service";
import AlertToastr from "utils/alert";
import { useAuth } from "../../../../Contexts/AuthProvider";
import { apiMmsBaseUrl } from "config";
import Api from "../../../../Services/Api.service";
import { normalizeErrorResponse } from "utils/form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAppSettings } from "Contexts/AppSettingsProvider";
import enGB from "date-fns/locale/en-GB";
import ru from "date-fns/locale/ru";
import { format, isBefore } from "date-fns";
import EventIcon from "@material-ui/icons/Event";
import { useQueryClient } from 'react-query'

const UninstallComponentModal = ({
  isOpen,
  handleClose,
  departmentId,
  addedComponent,
  componentIds,
  onSuccessReplace,
  isOpenManager,
  setExpandedIds,
}) => {
 
 
  const emptyComponentStateOption = { id: "empty", name: "Select state" };
  const translation = useTranslation();
  const { currentCompany } = useAuth();
  const { currentUser } = useAuth();
  const { appLanguage } = useAppSettings();
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false)
  const isSuperUser = useMemo(() => currentUser.isSuperuser(), [currentUser]);
 
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [selectedInputDateTime, setSelectedInputDateTime] = useState(format(new Date(), 'MM/dd/yyyy, HH:mm'));
  const [uninstallWorkhours, setUninstallWorkhours] = useState(
    addedComponent[0]?.workhoursTotalCalculated
  );
  const [selectedState, setSelectedState] = useState("disposed");
  const [states, setStates] = useState([]);


  const [textFieldKey, setTextFieldKey] = useState(0);
  const [errors, setErrors] = useState({});
  const [disableButtons, setDisabledButtons] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [isDefaultDateSet, setIsDefaultDateSet] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const queryClient = useQueryClient()
 

  useEffect(() => {
    if (addedComponent[0]?.name && searchTerm !== addedComponent[0]?.name) {
      setSearchTerm(addedComponent[0]?.name);
      setUninstallWorkhours(addedComponent[0]?.workhoursTotalCalculated);
    }
  }, [addedComponent, searchTerm, setSearchTerm, setUninstallWorkhours]);

  useEffect(() => {
    registerLocale("English", enGB);
    registerLocale("Russian", ru);

    function handleResize() {
      setIsMobile(window.innerWidth <= 576);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetchComponentStates() {
      if (currentCompany?.id) {
        const componentStatesRequest = await Api.get(
          `${apiMmsBaseUrl}/options/component-states`,
          {
            headers: isSuperUser ? { CompanyId: currentCompany.id } : {},
          }
        );
        if (componentStatesRequest.data) {
          const filteredStates = componentStatesRequest.data.filter(
            (state) => state.componentState !== "removed"
          );
          setStates(filteredStates);
          setSelectedState("disposed");
        }
      } else {
        const componentStatesRequest = await Api.get(
          `${apiMmsBaseUrl}/options/component-states`
        );
        if (componentStatesRequest.data) {
          setStates(componentStatesRequest.data);
        }
      }
    }
    fetchComponentStates();
  }, [currentCompany?.id, isSuperUser]);

  
 

  const handleMoveToComponentChange = (event) => {
    setErrors({});
    const value = event.target.value;
    setSelectedState(value);
    setTextFieldKey((prevKey) => prevKey + 1);
  };

  const handleWorkhoursChange = (value) => {
    setErrors({});
    setUninstallWorkhours(value);
  };

  const handleDateTimeChange = (date) => {
    const defaultHours = 23;
    const defaultMinutes = 59;

    if (isBefore(date, new Date())) {
      if (!isDefaultDateSet) {
        setIsDefaultDateSet(true);
        setSelectedDateTime(
          new Date(date.setHours(defaultHours, defaultMinutes))
        );
        setSelectedInputDateTime(format(new Date(date.setHours(defaultHours, defaultMinutes)), 'MM/dd/yyyy, HH:mm'))
      } else {
        setSelectedInputDateTime(format(new Date(date), "MM/dd/yyyy, HH:mm"));
        setSelectedDateTime(new Date(date))
      }
    } else {
      setIsDefaultDateSet(false);
      setSelectedInputDateTime(format(date, "MM/dd/yyyy, HH:mm"));
      setSelectedDateTime(date)
    }
  };

  const handleInputDateTimeChange = (date) => {
    setSelectedInputDateTime(date)
    setSelectedDateTime(new Date(date))
  };

  const handleOpenDatePicker = () => {
    setOpenDatePicker(true);
  };

  const handleCloseDatePicker = () => {
    setOpenDatePicker(false);
  };

  const handleSaveButtonClick = () => {
    const oldComponentId = componentIds[0];
    const oldComponentState = selectedState === "empty" ? null : selectedState;
    const uninstallDate = 
    isDefaultDateSet
      ? 
      selectedDateTime
      : selectedDateTime
      ;

    setLoading(true)

    MmsService.uninstallComponent(
      oldComponentId,
      oldComponentState,
      uninstallWorkhours,
      uninstallDate
    )
      .then((response) => {
        if (response.data.isSuccessful) {
          setIsDefaultDateSet(false);
          setSelectedDateTime(new Date());
          setSelectedInputDateTime(format(new Date(), 'MM/dd/yyyy, HH:mm'));
        }
        if (onSuccessReplace) onSuccessReplace();
        queryClient.invalidateQueries(['components']).then()
        setExpandedIds(response.data.expandedIds);
        isOpenManager(false);
        setErrors({});
        setSelectedState("disposed");
        setLoading(false)
        AlertToastr.showAlert(
          `Your component ${addedComponent[0]?.fullName} has been successfully uninstalled`
        );
      })
      .catch((error) => {
        setLoading(false)
        if (!error || !error.response || !error.response.data) return;
        const resErrors = normalizeErrorResponse(error.response.data);
        if (resErrors.errors) setErrors(resErrors.errors);
        setDisabledButtons(false);
        if (resErrors && resErrors.message)
          AlertToastr.showErrorAlert(resErrors.message);

        if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        )
          error.response.data.errors
            .filter((x) => x.PropertyName === "")
            .map((x) => {
              AlertToastr.showErrorAlert(x.ErrorMessage);
              return x;
            });
      });
  };

  const handleCloseModal = () => {
    setSelectedState(emptyComponentStateOption.id);
    handleClose();
    setSelectedState("disposed");
    setErrors({});
    setUninstallWorkhours(addedComponent[0]?.workhoursTotalCalculated);
    setSelectedDateTime(new Date());
    setSelectedInputDateTime(format(new Date(), 'MM/dd/yyyy, HH:mm'));
    setIsDefaultDateSet(false);
    setOpenDatePicker(false)
  };
 



  return (
    <Dialog
      open={isOpen}
      onClose={(e) => {
        handleCloseModal();
        setErrors({});
      }}
      className="respModal autoHeight"
      style={{ width: "448px", marginLeft: "auto", marginRight: "auto" }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleSaveButtonClick();
        }
        if (e.key === "Escape") {
          handleCloseModal();
        }
      }}
    >
      <div className="mb16">
        <h1 className="workhoursUpdateModalTitle">
          {translation.uninstallComponentModalLabel}
        </h1>
      </div>
      <div className="mb6">
        <h2 className="workhoursUpdateJobTitle">
          {addedComponent[0]?.fullName}
        </h2>
      </div>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="mb16"
      >
        <div style={{ width: "48%" }}>
          <FormLabel component="legend" className="mb5">
            {translation.uninstallComponentModalUninstalledAtLabel}
          </FormLabel>
          <TextField
            id="replaceModalReplaceAt"
            value={uninstallWorkhours}
            error={
              errors &&
              errors.workhoursTotalCalculated &&
              errors.workhoursTotalCalculated.length > 0
            }
            type="number"
            variant="outlined"
            margin="normal"
            // label={translation.replaceComponentModalComponentWorkhoursLabel}
            onChange={(e) => handleWorkhoursChange(e.target.value)}
            fullWidth
          />
        </div>

        <div style={{ width: "48%" }}>
          <FormLabel component="legend" style={{ marginBottom: "5px" }}>
            {translation.uninstallComponentModalUninstalledOnLabel}
          </FormLabel>
          <TextField
            id="replaceModalReplaceOn"
            onChange={(event) => handleInputDateTimeChange(event.target.value)}
            // className="formControlDatePicker"
            InputProps={{
              endAdornment: (
                <EventIcon
                  onClick={handleOpenDatePicker}
                  className="datePickerCalendarIcon"
                />
              ),
            }}
            variant="outlined"
            margin="normal"
            error={
              errors &&
              errors.selectedDateTime &&
              errors.selectedDateTime.length > 0
            }
            value={
              // selectedDateTime
              //   ? format(selectedDateTime, 'MM/dd/yyyy, HH:mm')
              //   : null
              selectedInputDateTime
            }
            locale={appLanguage}                                 
            fullWidth
          />

          {openDatePicker && (
            <div className="replaceModalDatepickerContainer">
              <DatePicker
                inline
                onClickOutside={() => setOpenDatePicker(false)}
                onClose={handleCloseDatePicker}
                id="replaceModalReplaceOn"
                onChange={handleDateTimeChange}
                maxDate={new Date("12/31/2999")}
                showTimeSelect
                timeIntervals={1}
                error={
                  errors &&
                  errors.selectedDateTime &&
                  errors.selectedDateTime.length > 0
                }
                selected={selectedDateTime}
                // withPortal={isMobile}
                locale={appLanguage}
                dateFormat="M/d/yyyy, HH:mm"
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <FormLabel component="legend" className="mb5">
          Move replaced component to:
        </FormLabel>
        <div className="">
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={
              errors && errors.oldComponentState
                ? errors.oldComponentState.join("\n")
                : ""
            }
          >
            <TextField
              type="number"
              variant="outlined"
              margin="normal"
              fullWidth
              select
              className="mb16"
              value={selectedState}
              onChange={handleMoveToComponentChange}
              error={
                errors &&
                errors.oldComponentState &&
                errors.oldComponentState.length > 0
              }
              key={textFieldKey}
              label={translation.replaceComponentModalStateFieldLabel}
              id="replaceModalChangeComponentState"
            >
              <MenuItem value={emptyComponentStateOption.id}>
                {emptyComponentStateOption.name}
              </MenuItem>
              {states.map((state, index) => (
                <MenuItem
                  id="replaceModalChooseComponentState"
                  key={index}
                  value={state.componentState}
                  onClick={() => setSelectedState(state.componentState)}
                  className=""
                >
                  {state.name}
                </MenuItem>
              ))}
            </TextField>
          </Tooltip>
        </div>
      </div>
      <div className="btnHolder end fixedWidthBtn">
        <Button
          variant="outlined"
          id="replaceModalCancelBtn"
          fullWidth
          onClick={handleCloseModal}
        >
          {translation.cancelButtonLabel}
        </Button>       
        <div className="part">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            id="replaceModalSaveBtn"
            style={{ marginBottom: "0" }}
            onClick={handleSaveButtonClick}
            disabled={!!disableButtons || loading}
          >
            Save
          </Button>
             {loading && <CircularProgress size={24} />}
         </div>
      </div>
    </Dialog>
  );
};

const mapDispatchToProps = {
  setRunComponentsUpdate,
};

export default connect(null, mapDispatchToProps)(UninstallComponentModal);