import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { apiBaseUrl } from '../../../config';
import ListTable from '../../../Components/ListTable';
import { IconButton } from '@material-ui/core';
import DeleteItemModal from '../../../Components/modals/delete-item-modal/delete-item-modal.component';
import { jobTimeFormat } from '../../../helpers';
import useTranslation from '../../../Hooks/useTranslation';
import AlertToastr from '../../../utils/alert';
import { useUsersSettings } from '../UsersSettingsContext';
import { useAuth } from '../../../Contexts/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentJob } from '../../../Store/modules/dashboard/actions';
import DisabledLabel from '../../../Components/DisabledLabel';
import { Switch, TextField, MenuItem } from "@material-ui/core";
import {
  getJobsDateIntervals,
  useJobsSettings,
} from "../../../Hooks/useCompanySettings";

const RemoveIcon = () => <div className="removeIcon" />;


const DatabaseManagement = () => {

    const currentJob = useSelector(state => state.dashboard.currentJob);
    const dispatch = useDispatch();
    const { userLoaded, currentUser } = useAuth();
    const { currentCompany } = useUsersSettings();
    const translation = useTranslation();
    const queryClient = useQueryClient();
    const [data, setData] = useState([]);
    const [showRemoveJobModal, setShowRemoveJobModal] = useState(false);
    const [activeJob, setActiveJob] = useState(null);
    const [jobIntervals, setJobIntervals] = useState([]);
  const [cleanupOldData, setCleanupOldData] = useState(false);
  const [cleanupInterval, setCleanupInterval] = useState("");
  const [cleanupValue, setCleanupValue] = useState("");

  const [cleanupOldDataChanged, setCleanupOldDataChanged] = useState(false);
  const [cleanupIntervalChanged, setCleanupIntervalChanged] = useState(false);
  const [cleanupValueChanged, setCleanupValueChanged] = useState(false);
  const isSuperUser = useMemo(() => currentUser.isSuperuser(), [currentUser]);
  const isMasterUser = useMemo(() => currentUser.isMaster(), [currentUser]);


  useEffect(() => {
    getJobsDateIntervals()
      .then((request) => {
        setJobIntervals(request);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const settingsRequest = useQuery(
    ["Settings", currentCompany],
    async () => {
      const config = !currentUser.isSuperuser()
        ? {}
        : {
            headers: {
              CompanyId: currentCompany?.id,
            },
          };
      return await axios.get(`${apiBaseUrl}/Settings`, config);
    },
    {
      keepPreviousData: true,
      enabled: userLoaded,
      onSuccess: (response) => {
        setCleanupOldData(response.data.cleanupOldData);
        setCleanupInterval(response.data.cleanupInterval);
        setCleanupValue(response.data.cleanupValue);
      },
    }
  );

  const saveJobsSettings = useJobsSettings(
    cleanupOldData,
    cleanupValue,
    cleanupInterval
  );

  useEffect(() => {
    if (
      cleanupOldDataChanged ||
      cleanupIntervalChanged ||
      cleanupValueChanged
    ) {
      saveJobsSettings();
      setCleanupOldDataChanged(false);
      setCleanupIntervalChanged(false);
      setCleanupValueChanged(false);
    }
  }, [
    cleanupOldDataChanged,
    cleanupIntervalChanged,
    cleanupValueChanged,
    saveJobsSettings,
  ]);

  const handleCleanupOldDataChange = () => {
    setCleanupOldData(!cleanupOldData);
    setCleanupOldDataChanged(true);
    if (!cleanupOldData) {
      AlertToastr.showAlert(translation.autoDeleteJobMessage);
    }
  };

  const handleCleanupValueChange = (event) => {
    const value = event.target.value;
    setCleanupValue(value);
    setCleanupValueChanged(true);
  };

  const handleCleanupIntervalChange = (event) => {
    const interval = event.target.value;
    setCleanupInterval(interval);
    setCleanupIntervalChanged(true);
  };

  


    const tableColumns = useMemo(() => {
        return [
            {
                Header: translation.tableColumnName,
                accessor: 'name',
                Cell: ({ value, row }) => {
                    const { isDeleting } = row.original;
                    return isDeleting ? <DisabledLabel>{value}</DisabledLabel> : value;
                },
            },
            {
                Header: translation.tableColumnStartTime,
                accessor: 'startTime',
                Cell: ({ value, row }) => {
                    const { isDeleting } = row.original;
                    const formattedValue = jobTimeFormat(value);
                    return isDeleting ? <DisabledLabel>{formattedValue}</DisabledLabel> : value;
                },
            },
            {
                Header: translation.tableColumnStopTime,
                accessor: 'stopTime',
                Cell: ({ value, row }) => {
                    if (!value) return '-';
                    const { isDeleting } = row.original;
                    const formattedValue = jobTimeFormat(value);
                    return isDeleting ? <DisabledLabel>{formattedValue}</DisabledLabel> : value;
                },
            },
            {
                Header: translation.tableColumnJobType,
                accessor: 'jobType',
                Cell: ({ value, row }) => {
                    const output = value === undefined ? '-' : value;
                    const { isDeleting } = row.original;
                    return isDeleting ? <DisabledLabel>{output}</DisabledLabel> : output;
                },
            },
            {
                Header: translation.tableColumnSize,
                accessor: 'sizeMb',
                Cell: ({ value, row }) => {
                    const { isDeleting } = row.original;
                    return isDeleting ? <DisabledLabel>{value}</DisabledLabel> : value;
                },
            },
            {
                Header: '',
                id: 'actions',
                className: 'actionTableCell',
                Cell: ({ value, row }) => {
                    const { isDeleting } = row.original;
                    if (isDeleting) return null;
                    return (
                        <IconButton size={'small'}
                                    className={(!row.original.stopTime || row.original.sizeMb === 0) && !isSuperUser && !isMasterUser !== 0 ? "deleteTableItemDisabledBtn" : "deleteTableItemBtn"}
                                    onClick={() => {
                                        setActiveJob(row.original);
                                        setShowRemoveJobModal(true);
                                    }}
                                    disabled={(!row.original.stopTime || row.original.sizeMb === 0) && !isSuperUser && !isMasterUser}
                        >
                            <RemoveIcon />
                        </IconButton>
                    );
                },
            },
        ];
    }, [translation]);

    const jobsListRequest = useQuery(
        ['jobsSize', currentCompany],
        async () => {
            const config = !currentUser.isSuperuser() ? {} : {
                headers: {
                    'CompanyId': currentCompany?.id,
                },
            };
            return await axios.get(`${apiBaseUrl}/Jobs/short/sizes`, config);
        },
        { keepPreviousData: true, enabled: userLoaded },
    );

    useEffect(() => {
        if (jobsListRequest.isSuccess) {
            const data = jobsListRequest.data.status === 200 ? jobsListRequest.data.data : [];
            setData(data);
        }
    }, [jobsListRequest.isSuccess, jobsListRequest.data]);

    const jobRemoveMutation = useMutation((id) => axios.delete(`${apiBaseUrl}/Jobs/${id}`));

    const deleteJob = async () => {
        try {
            const response = await jobRemoveMutation.mutateAsync(activeJob.id);

            const successMessage = response?.data?.message;

            if (currentJob && currentJob.id === activeJob.id) {
                dispatch(setCurrentJob(null));
            }

            AlertToastr.showAlert(successMessage || translation.deleteJobMessage);
            await queryClient.invalidateQueries(['jobsSize']);
            await queryClient.invalidateQueries(['dbSystem']);
        } catch (e) {
            const message = e?.response?.data?.message;

            if (message) {
                AlertToastr.showErrorAlert(message);
            }
        }

        closeDeleteModal();
    };

    const closeDeleteModal = () => {
        setActiveJob(null);
        setShowRemoveJobModal(false);
    };

    if (settingsRequest.isLoading) return null;


    return (
      <div className="pageCard">
        <div className="companySettingsCard">
          <div className="flex">
          <Switch
            color="primary"
            checked={cleanupOldData}
            onChange={handleCleanupOldDataChange}
            // style={{ color: cleanupOldData ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.54)' }}
          />
            {/* <div>
              <div className="companySettingsCheckboxTitle">
                Delete jobs from cloud
              </div>
            </div> */}
          </div>
          <div className="flex">
            <div className="companySettingsSelectorTitle">
              Auto deleting older jobs:
            </div>
            <div className="companySettingsQtySelector">
              <TextField
                variant="outlined"
                margin="none"
                // disabled={!cleanupOldData}
                select
                fullWidth
                value={cleanupValue}
                onChange={handleCleanupValueChange}
              >
                {Array.from({ length: 12 }, (_, index) => (
                  <MenuItem
                    key={index + 1}
                    value={index + 1}
                    className="filterMenuItem"
                  >
                    {index + 1}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="companySettingsPeriodSelector">
              <TextField
                variant="outlined"
                margin="none"
                // disabled={!cleanupOldData}
                select
                fullWidth
                onChange={handleCleanupIntervalChange}
                value={cleanupInterval}
              >
                {jobIntervals.map((interval) => (
                  <MenuItem key={interval.id} value={interval.name}>
                    {interval.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>
        <ListTable
          emptyDataText={
            <span>
              <span>No jobs found.</span>
            </span>
          }
          request={jobsListRequest}
          columns={tableColumns}
          data={data}
        />
        <DeleteItemModal
          isOpen={showRemoveJobModal}
          loading={jobRemoveMutation.isLoading}
          isOpenManager={() => {}}
          onCancel={closeDeleteModal}
          message={translation.formatString(
            translation.removeJobModalMessageLabel,
            { name: activeJob?.name }
          )}
          onDeleteModalClose={deleteJob}
        />
      </div>
    );
};

export default DatabaseManagement;
