import React, { useMemo, useState } from 'react';
import useItemTypes from '../../../Hooks/useItemTypes';
import AddBoxMenu from '../../../Components/AddBoxMenu/AddBoxMenu.component';
import OptionsMenu from '../../../Components/OptionsMenu';
import { useAuth } from '../../../Contexts/AuthProvider';
import { useBoxes } from '../../../Contexts/BoxContext';
import { userHasPermission } from '../../../helpers';
import UnitJobDateRangeGlobalFilters from '../../../Components/filters/UnitJobDateRangeGlobalFilters';
import DownloadFileButton from '../../../Components/DownloadFileButton';
import useTranslation from 'Hooks/useTranslation';
import { Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import MapComponentModal from 'Components/MapComponentModal';

const UnitGroupHeader = props => {

    const {
        onAddBox,
        title,
        onLoadTemplate,
        onSaveTemplate,
        onExportCsv,
        onChangeUnit,
        showMapBtn,
        gpsData
    } = props;

    const { currentUser } = useAuth();
    const {
        unitBoxes,
        currentUnit,
        currentUnitModel,
        isMainGroup,
        unitBoxesFilters,
        changeUnitGlobalFilters,
        refreshBoxes,
        dataExportUnitsRequest
    } = useBoxes();
    const { data: itemTypes } = useItemTypes('units');
    const translation = useTranslation();
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);

    const allowManageTemplate = useMemo(() => userHasPermission(currentUser, 'manage_templates'), [currentUser]);

    const hideMapType = currentUnitModel?.latitude === undefined;

    const renderOptionsButton = () => {
        if (!isMainGroup) return null;

        return (
            <OptionsMenu
                refreshDisabled={!unitBoxes.length}
                onRefresh={() => refreshBoxes('units')}
                onChangeUnit={onChangeUnit}
                changeUnitDisabled={!currentUnit}
                isCurrentJobSet={!!currentUnit}
                allowManageTemplate={allowManageTemplate}
                disableSaveTemplate={!currentUnit && unitBoxes.length === 0}
                onLoadTemplate={onLoadTemplate}
                onSaveTemplate={onSaveTemplate}
                onExportCsv={onExportCsv}
                exportCsvLabel={translation.exportUnitToCsvLabel}
            />
        );
    };

    const renderGlobalFilters = () => {
        if (!isMainGroup) return null;
        // if (!currentUnitModel) return null;

        return (
            <UnitJobDateRangeGlobalFilters
                tabType='units'
                unitUid={currentUnitModel?.uId}
                inline
                onChange={changeUnitGlobalFilters}
                initData={unitBoxesFilters}
            />
        );
    };

    return (
      <>
        <div className="pageBtnSection">
          <div className="part">
            <div className="btnGroup">
              <div className="item">
                <AddBoxMenu
                  itemTypes={
                    hideMapType
                      ? itemTypes.filter((item) => item.itemType !== "map")
                      : itemTypes
                  }
                  onAddBox={onAddBox}
                  currentUnitModel={currentUnitModel}
                />
              </div>
              <div className="item">{renderOptionsButton()}</div>
              <div className="item">
                <DownloadFileButton
                  getExportRequest={dataExportUnitsRequest}
                  tabType="units"
                />
              </div>
            </div>
          </div>
          <div className="part centered centeredMobile">
            <div className="flex">
              <Button
                onClick={() => {
                  onChangeUnit();
                }}
                className="asideNavItem"
                fullWidth
                endIcon={<ArrowDropDownIcon />}
              >
                <span className="hasMaxWidth titleLimitSymbols">
                  {currentUnitModel ? currentUnitModel.name : "Select Unit"}
                </span>
              </Button>
              {showMapBtn && currentUnitModel?.uId ? (
                <Button
                  onClick={() => {
                    setIsMapModalOpen(true);
                  }}
                  style={{ width: "20px", height: "20px", padding: "0" }}
                >
                  <MyLocationIcon style={{ width: "20px", height: "20px" }} />
                </Button>
              ) : null}
            </div>
          </div>
          <div className="part end">{renderGlobalFilters()}</div>
        </div>
        <MapComponentModal
          currentUnitModel={currentUnitModel}
          isOpen={isMapModalOpen}
          onCancel={() => setIsMapModalOpen(false)}
          section="units"
          gpsData={gpsData}
        />
      </>
    );
};

export default UnitGroupHeader;
