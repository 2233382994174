import React, { Fragment, useState, useEffect } from "react";
import useTranslation from "../../Hooks/useTranslation";
import { useBoxes } from "../../Contexts/BoxContext";
import { deleteEmptyStringProperties } from "../../helpers";
import { MenuItem, TextField } from "@material-ui/core";
import { useAsyncDebounce } from "react-table";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGB from "date-fns/locale/en-GB";
import ru from "date-fns/locale/ru";
import { format } from "date-fns";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";

registerLocale("en", enGB);
registerLocale("ru", ru);

const DataTableCustomDateBoxFilter = (props) => {
  const {
    onChange,
    initData,
    dateFrom,
    dateTo,
    onDateFromChange,
    onDateToChange,
    showStepFilter = true,
    saveInLocalTime,
  } = props;

  const translation = useTranslation();
  const { dateTypesOptions, dateTypeIsLoading, updateChartDateRange } = useBoxes();
  const [typeValue, setTypeValue] = useState(initData?.filterType || "none");
  const [value, setValue] = useState(initData?.value || "");

  const [openDatePickerFrom, setOpenDatePickerFrom] = useState(false);
  const [openDatePickerTo, setOpenDatePickerTo] = useState(false);
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [dateFromInputValue, setDateFromInputValue] = useState("");
  const [dateToInputValue, setDateToInputValue] = useState("");

  useEffect(() => {
    if (dateFrom) {
      const parsedDateFrom = moment(dateFrom);
      if (parsedDateFrom.isValid()) {
        setSelectedDateFrom(parsedDateFrom.toDate());
        setDateFromInputValue(parsedDateFrom.format("DD.MM.yyyy, HH:mm:ss"));
      } else {
        setSelectedDateFrom(null);
        setDateFromInputValue("");
      }
    } else {
      setSelectedDateFrom(null);
      setDateFromInputValue("");
    }
  }, [dateFrom]);

  useEffect(() => {
    if (dateTo) {
      const parsedDateTo = moment(dateTo);
      if (parsedDateTo.isValid()) {
        setSelectedDateTo(parsedDateTo.toDate());
        setDateToInputValue(parsedDateTo.format("DD.MM.yyyy, HH:mm:ss"));
      } else {
        setSelectedDateTo(null);
        setDateToInputValue("");
      }
    } else {
      setSelectedDateTo(null);
      setDateToInputValue("");
    }
  }, [dateTo]);

  const handleChangeFilterType = (event) => {
    const selectValue = event.target.value;

    if (selectValue === "none") {
      onChange(
        deleteEmptyStringProperties({
          filterType: selectValue,
          value: "",
        })
      );
    }

    setTypeValue(selectValue);
    setValue("");
  };

  const onValueChange = useAsyncDebounce((filterType, value) => {
    onChange(
      deleteEmptyStringProperties({
        filterType: value ? filterType : "none",
        value: value === "" ? 0 : value,
      })
    );
  }, 700);

  useEffect(() => {
    updateChartDateRange(selectedDateFrom, selectedDateTo)
  }, [selectedDateFrom, selectedDateTo, updateChartDateRange])

  const getBrowserLanguage = () => {
    const lang = navigator.language || "en-GB";
    if (lang.startsWith("ru")) return "ru";
    return "en-GB";
  };

  const userLocale = getBrowserLanguage();

  const handleChangeValue = (e) => {
    const { value } = e.target;
    setValue(value);
    onValueChange(typeValue, value);
  };

  const handleChangeDateFromValue = useAsyncDebounce((date) => {
    const dateToUtc = dateTo
      ? moment(dateTo).utc().format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const dateToOutput = saveInLocalTime ? dateTo : dateToUtc;

    if (!date) {
      onDateFromChange(null);
      onChange(
        deleteEmptyStringProperties({
          filterType: typeValue,
          value,
          dateFrom: null,
          dateTo: dateToOutput,
        })
      );
      return;
    }
    const datetime = moment(date);
    if (datetime.isValid()) {
      const datetimeUtc = datetime.clone();
      onChange(
        deleteEmptyStringProperties({
          filterType: typeValue,
          value,
          dateFrom: saveInLocalTime
            ? datetime
            : datetimeUtc.utc().format("YYYY-MM-DDTHH:mm:ss"),
          dateTo: dateToOutput,
        })
      );
      return onDateFromChange(datetime.format("YYYY-MM-DDTHH:mm:ss"));
    }
  }, 700);

  const handleChangeDateToValue = useAsyncDebounce((date) => {
    const dateFromUtc = dateFrom
      ? moment(dateFrom).utc().format("YYYY-MM-DDTHH:mm:ss")
      : null;
    const dateFromOutput = saveInLocalTime ? dateFrom : dateFromUtc;

    if (!date) {
      onDateToChange(null);
      onChange(
        deleteEmptyStringProperties({
          filterType: typeValue,
          value,
          dateFrom: dateFromOutput,
          dateTo: null,
        })
      );
      return;
    }
    const datetime = moment(date);
    if (datetime.isValid()) {
      const datetimeUtc = datetime.clone();
      onChange(
        deleteEmptyStringProperties({
          filterType: typeValue,
          value,
          dateFrom: dateFromOutput,
          dateTo: saveInLocalTime
            ? datetime
            : datetimeUtc.utc().format("YYYY-MM-DDTHH:mm:ss"),
        })
      );
      return onDateToChange(datetime.format("YYYY-MM-DDTHH:mm:ss"));
    }
  }, 700);

  return (
    <div className="box-filters">
      <div className="box-filters-container">
        <div className="DateTimePickerFrom">
          <TextField
            variant="outlined"
            label={translation.fromLabel}
            placeholder="dd.mm.yyyy hh:mm:ss"
            value={dateFromInputValue}
            onChange={(e) => {
              setDateFromInputValue(e.target.value);
              const parsedDate = moment(
                e.target.value,
                "DD.MM.yyyy, HH:mm:ss",
                true
              );
              if (parsedDate.isValid()) {
                setSelectedDateFrom(parsedDate.toDate());
                handleChangeDateFromValue(parsedDate.toDate());
              }
            }}
            InputProps={{
              endAdornment: (
                <EventIcon
                  onClick={() => setOpenDatePickerFrom(true)}
                  className="datePickerCalendarIcon"
                />
              ),
            }}
            fullWidth
          />
          {openDatePickerFrom && (
            <div className="datatableDatepickerContainer">
              <DatePicker
                inline
                selected={selectedDateFrom}
                onChange={(date) => {
                  setSelectedDateFrom(date);
                  setDateFromInputValue(
                    moment(date).format("DD.MM.yyyy, HH:mm:ss")
                  );
                  handleChangeDateFromValue(date);
                  setOpenDatePickerFrom(false);
                }}
                showTimeSelect
                timeIntervals={1}
                dateFormat="dd.MM.yyyy, HH:mm:ss"
                onClickOutside={() => setOpenDatePickerFrom(false)}
                locale={userLocale}
              />
            </div>
          )}
        </div>
        <div className="DateTimePickerTo">
          <TextField
            variant="outlined"
            label={translation.toLabel}
            placeholder="dd.mm.yyyy hh:mm:ss"
            value={dateToInputValue}
            onChange={(e) => {
              setDateToInputValue(e.target.value);
              const parsedDate = moment(
                e.target.value,
                "DD.MM.yyyy, HH:mm:ss",
                true
              );
              if (parsedDate.isValid()) {
                setSelectedDateTo(parsedDate.toDate());
                handleChangeDateToValue(parsedDate.toDate());
              }
            }}
            InputProps={{
              endAdornment: (
                <EventIcon
                  onClick={() => setOpenDatePickerTo(true)}
                  className="datePickerCalendarIcon"
                />
              ),
            }}
            fullWidth
          />
          {openDatePickerTo && (
            <div className="datatableDatepickerContainer">
              <DatePicker
                inline
                selected={selectedDateTo}
                onChange={(date) => {
                  setSelectedDateTo(date);
                  setDateToInputValue(
                    moment(date).format("DD.MM.yyyy, HH:mm:ss")
                  );
                  handleChangeDateToValue(date);
                  setOpenDatePickerTo(false);
                }}
                showTimeSelect
                timeIntervals={1}
                dateFormat="dd.MM.yyyy, HH:mm:ss"
                onClickOutside={() => setOpenDatePickerTo(false)}
                locale={userLocale}
              />
            </div>
          )}
        </div>
      </div>

      {showStepFilter && (
        <Fragment>
          <div className="box-filters-container">
            <div className="stepSizeChange">
              <TextField
                disabled={dateTypeIsLoading}
                variant="outlined"
                margin="none"
                select
                fullWidth
                value={typeValue}
                onChange={handleChangeFilterType}
              >
                {dateTypesOptions.map((item) => (
                  <MenuItem
                    className="filterMenuItem"
                    key={item.filterType}
                    value={item.filterType}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            {typeValue !== "none" && (
              <div className="stepSizeValue">
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="none"
                  name="value"
                  label={translation.valueLabel}
                  type="number"
                  value={value}
                  onChange={handleChangeValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default DataTableCustomDateBoxFilter;
