import Api from 'Services/Api.service'
import { apiMmsBaseUrl } from 'config'
import { appLangCode } from '../../helpers'

const emptyConst = 'empty'

class MmsApiService {

    createDepartment = async ({ name, note, employeeId }, companyId) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/departments`, { name: name, note: note, companyId: companyId, employeeId })
        } catch (e) {
            throw e
        }
    }

    getDepartments = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/departments`)
        } catch (e) {
            throw e
        }
    }

    getDepartmentsByCompanyId = async (companyId) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/departments/by-companyid/${companyId}`)
        } catch (e) {
            throw e
        }
    }

    updateDepartment = async (id, params) => {
        try {
            return await Api.put(`${apiMmsBaseUrl}/departments`, { id: id, ...params })
        } catch (e) {
            throw e
        }
    }

    updateDepartmentNote = async (departmentId, note) => {
        try {
            return await Api.patch(`${apiMmsBaseUrl}/departments/note`, { id: departmentId, note: note })
        } catch (e) {
            throw e
        }
    }

    deleteDepartment = async (id, companyId) => {
        try {
            return await Api.delete(`${apiMmsBaseUrl}/departments/${id}`, {
                headers: {
                    'CompanyId': companyId
                }
            })
        } catch (e) {
            throw e
        }
    }

    createEmployee = async (employee) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/employees`, employee)
        } catch (e) {
            throw e
        }
    }

    getEmployees = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/employees`)
        } catch (e) {
            throw e
        }
    }

    getEmployeesByCompanyId = async (companyId) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/employees/by-companyid/${companyId}`)
        } catch (e) {
            throw e
        }
    }

    getEmployeesByDepartmentIdSplitted = async (departmentId) => {
        const url = departmentId
        ? `${apiMmsBaseUrl}/employees/splitted?departmentId=${departmentId}`
        : `${apiMmsBaseUrl}/employees/splitted`
        try {
            return await Api.get(url)
        } catch (e) {
            throw e
        }
    }

    updateEmployee = async (employee) => {
        try {
            return await Api.put(`${apiMmsBaseUrl}/employees`, employee)
        } catch (e) {
            throw e
        }
    }

    deleteEmployee = async (id, companyId) => {
        try {
            return await Api.delete(`${apiMmsBaseUrl}/employees/${id}`, {
                headers: {
                    'CompanyId': companyId
                }
            })
        } catch (e) {
            throw e
        }
    }

    getDimensionsOptions = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/dimensions`)
        } catch (e) {
            throw e
        }
    }

    getWeightOptions = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/weight`)
        } catch (e) {
            throw e
        }
    }

    getUpdateTypesOptions = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/update-types`)
        } catch (e) {
            throw e
        }
    }

    getTypesOptions = async (lang) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/activity-types`, {
                headers: {
                    'Accept-Language': appLangCode(lang)
                }
            })
        } catch (e) {
            throw e
        }
    }

    getOccurenceOptions = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/occurence`)
        } catch (e) {
            throw e
        }
    }

    getStatusOptions = async (lang) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/statuses`, {
                headers: {
                    'Accept-Language': appLangCode(lang)
                }
            })
        } catch (e) {
            throw e
        }
    }

    getStates = async (lang) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/states`)
        } catch (e) {
            throw e
        }
    }

    getPriorityOptions = async (lang) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/priorities`, {
                headers: {
                    'Accept-Language': appLangCode(lang)
                }
            })
        } catch (e) {
            throw e
        }
    }

    getSkillOptions = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/skills`)
        } catch (e) {
            throw e
        }
    }

    getToolOptions = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/tools`)
        } catch (e) {
            throw e
        }
    }

    getComponentsWithDependencies = async (departmentId) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/components/by-departmentid/${departmentId}/short/with-dependencies`)
        } catch (e) {
            throw e
        }
    }

    getWorkTimeTypeOptions = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/work-time-ranges`)
        } catch (e) {
            throw e
        }
    }

    getFrequencyOptions = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/frequencies`)
        } catch (e) {
            throw e
        }
    }

    getActionTimeTypeOptions = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/action-trigger-ranges`)
        } catch (e) {
            throw e
        }
    }

    getNotificationTimeRangesOptions = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/notification-time-ranges`)
        } catch (e) {
            throw e
        }
    }

    getFilterTimeTypeOptions = async (lang) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/options/filter-date-types`, {
                headers: {
                    'Accept-Language': appLangCode(lang)
                }
            })
        } catch (e) {
            throw e
        }
    }

    createComponent = async (component, departmentId, parentComponentId) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/components`, { ...component, departmentId: departmentId, parentComponentId: parentComponentId })
        } catch (e) {
            throw e
        }
    }

    getComponents = async (departmentId) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/components/by-departmentid/${departmentId}/short`)
        } catch (e) {
            throw e
        }
    }

    getComponentsShort = async (departmentId) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/components/by-departmentid/${departmentId}/short`)
        } catch (e) {
            throw e
        }
    }

    getComponent = async (componentId) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/components/${componentId}`)
        } catch (e) {
            throw e
        }
    }

    getComponentsByCompanyId = async (companyId) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/components/by-companyid/${companyId}`)
        } catch (e) {
            throw e
        }
    }

    updateComponent = async (component) => {
        try {
            return await Api.put(`${apiMmsBaseUrl}/components`, component)
        } catch (e) {
            throw e
        }
    }

    deleteComponent = async (id) => {
        try {
            return await Api.delete(`${apiMmsBaseUrl}/components/${id}`)
        } catch (e) {
            throw e
        }
    }

    deleteComponents = async (ids) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/components/delete`, { ids: ids })
        } catch (e) {
            throw e
        }
    }

    createUnassignedComponent = async (component) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/components/unassigned`, { ...component, state: "fieldReady", parentComponentId: null, workhoursUpdateType: "manual" })
        } catch (e) {
            throw e
        }
    }

    addUnassignedComponent = async (component) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/components/install`, component )
        } catch (e) {
            throw e
        }
    }

    deleteUnassignedComponent = async (id) => {
        try {
            return await Api.delete(`${apiMmsBaseUrl}/components/unassigned/${id}`)
        } catch (e) {
            throw e
        }
    }

    deleteUnassignedComponents = async (ids) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/components/unassigned/delete`, { ids: ids })
        } catch (e) {
            throw e
        }
    }

    changeComponentDepartment = async (componentIds, departmentId) => {
        try {
            return await Api.put(`${apiMmsBaseUrl}/components/department`, { ids: componentIds, departmentId: departmentId })
        } catch (e) {
            throw e
        }
    }

    cloneComponents = async (componentIds, departmentId, parentComponentId) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/components/clone`, { ids: componentIds, departmentId: departmentId, parentComponentId: parentComponentId })
        } catch (e) {
            throw e
        }
    }

    replaceComponent = async (oldComponentId, newComponentId, oldComponentState, departmentId, newComponentSerial, note, newComponentName, replaceWorkhours, replaceDate) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/components/replace`, { oldComponentId: oldComponentId, newComponentId: newComponentId, oldComponentState: oldComponentState, departmentId: departmentId, newComponentSerial: newComponentSerial, note: note, newComponentName: newComponentName, replaceWorkhours: replaceWorkhours, replaceDate: replaceDate})
        } catch (e) {
            throw e
        }
    }

    uninstallComponent = async (oldComponentId, oldComponentState, uninstallWorkhours, uninstallDate) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/components/uninstall`, { componentId: oldComponentId, componentState: oldComponentState, uninstallWorkhours: uninstallWorkhours, uninstallDate: uninstallDate})
        } catch (e) {
            throw e
        }
    }

    cloneModifiedComponent = async (componentId, departmentId, parentComponentId, component) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/components/clone/modified`, { ...component, id: componentId, departmentId: departmentId, parentComponentId: parentComponentId })
        } catch (e) {
            throw e
        }
    }

    setComponentActivity = async (componentId, isActive) => {
        try {
            if (isActive)
                return await Api.put(`${apiMmsBaseUrl}/components/${componentId}/activate`)
            else
                return await Api.put(`${apiMmsBaseUrl}/components/${componentId}/deactivate`)
        } catch (e) {
            throw e
        }
    }

    updateComponentWorkHours = async (componentId, workhours, force = false) => {
        try {
          return await Api.put(`${apiMmsBaseUrl}/components/workhours`, {
            id: componentId,
            workhours: +workhours,
            forceUpdate: force,
          });
        } catch (e) {
          throw e;
        }
    };

    updateComponentNote = async (componentId, note) => {
        try {
            return await Api.patch(`${apiMmsBaseUrl}/components/note`, { id: componentId, note: note })
        } catch (e) {
            throw e
        }
    }

    updateUnassignedComponentState = async (id, state) => {
        try {
            return await Api.put(`${apiMmsBaseUrl}/components/${id}/set-state/${state}`, { id: id, state: state })
        } catch (e) {
            throw e
        }
    }

    createPart = async (part, companyId) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/parts`, { ...part, companyId })
        } catch (e) {
            throw e
        }
    }

    getPartsList = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/parts`)
        } catch (e) {
            throw e
        }
    }

    getPartsByCompanyId = async (companyId) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/parts/by-companyid/${companyId}`)
        } catch (e) {
            throw e
        }
    }

    cloneParts = async (partIds, departmentId) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/parts/clone`, { ids: partIds, departmentId: departmentId })
        } catch (e) {
            throw e
        }
    }

    updatePart = async (part) => {
        try {
            return await Api.put(`${apiMmsBaseUrl}/parts`, part)
        } catch (e) {
            throw e
        }
    }

    deletePart = async (id) => {
        try {
            return await Api.delete(`${apiMmsBaseUrl}/parts/${id}`)
        } catch (e) {
            throw e
        }
    }

    deleteParts = async (ids) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/parts/delete`, { ids: ids })
        } catch (e) {
            throw e
        }
    }

    getComponentsCatalog = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/components/catalog`)
        } catch (e) {
            throw e
        }
    }

    getPartsCatalog = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/parts/catalog`)
        } catch (e) {
            throw e
        }
    }

    getRecommendedSpareParts = async (departmentId, componentIds) => {
        let idParams = componentIds.map((c, index) => `componentIds[${index}]=${c}`).join('&')
        try {
            return await Api.get(`${apiMmsBaseUrl}/parts/recommended?departmentId=${departmentId}&${idParams}`)
        } catch (e) {
            throw e
        }
    }

    createActivity = async (activity, departmentId, componentId) => {
        const url = activity.addAsCompleted ? `${apiMmsBaseUrl}/activities/complete` : `${apiMmsBaseUrl}/activities`;

        try {
            return await Api.post(url, { ...activity, departmentId: departmentId, componentId: componentId })
        } catch (e) {
            throw e
        }
    }

    getActivities = async (departmentId) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/activities/by-departmentid/${departmentId}`)
        } catch (e) {
            throw e
        }
    }

    getFiltredActivities = async (departmentId, filterInfo) => {
        let parametersString = `?DepartmentId=${departmentId}`
        if (filterInfo.statusId !== emptyConst) {
            parametersString = `${parametersString}&Status=${filterInfo.statusId}`
        }
        if (filterInfo.dateRangeId !== emptyConst) {
            parametersString = `${parametersString}&FilterDateType=${filterInfo.dateRangeId}`
        }
        if (filterInfo.componentId !== emptyConst) {
            parametersString = `${parametersString}&componentId=${filterInfo.componentId}`
        }
        if (filterInfo.priorityId !== emptyConst) {
            parametersString = `${parametersString}&priority=${filterInfo.priorityId}`
        }
        if (filterInfo.assigneeId !== emptyConst) {
            parametersString = `${parametersString}&assigneeId=${filterInfo.assigneeId}`
        }
        if (filterInfo.searchTerm) {
            parametersString = `${parametersString}&searchTerm=${filterInfo.searchTerm}`
        }
        try {
            return await Api.get(`${apiMmsBaseUrl}/activities/filter${parametersString}`)
        } catch (e) {
            throw e
        }
    }

    getFiltredUcActivities = async (departmentId, filterInfo) => {
        let parametersString = `?DepartmentId=${departmentId}`
       
        if (filterInfo.statusId !== emptyConst) {
            parametersString = `${parametersString}&Status=${filterInfo.statusId}&forUnassignedComponents=true`
        }
        if (filterInfo.dateRangeId !== emptyConst) {
            parametersString = `${parametersString}&FilterDateType=${filterInfo.dateRangeId}&forUnassignedComponents=true`
        }
        if (filterInfo.componentId !== emptyConst) {
            parametersString = `${parametersString}&componentId=${filterInfo.componentId}&forUnassignedComponents=true`
        }
        if (filterInfo.priorityId !== emptyConst) {
            parametersString = `${parametersString}&priority=${filterInfo.priorityId}&forUnassignedComponents=true`
        }
        if (filterInfo.assigneeId !== emptyConst) {
            parametersString = `${parametersString}&assigneeId=${filterInfo.assigneeId}&forUnassignedComponents=true`
        }
        if (filterInfo.searchTerm) {
            parametersString = `${parametersString}&searchTerm=${filterInfo.searchTerm}&forUnassignedComponents=true`
        }
        try {
            return await Api.get(`${apiMmsBaseUrl}/activities/filter${parametersString}`)
        } catch (e) {
            throw e
        }
    }

    assingPersonToActivity = async (activityId, assigneeId) => {
        try {
            return await Api.put(`${apiMmsBaseUrl}/activities/assignee`, { id: activityId, assigneeId: assigneeId })
        } catch (e) {
            throw e
        }
    }

    updateActivity = async (activity) => {
        const url = activity.addAsCompleted ? `${apiMmsBaseUrl}/activities/complete` : `${apiMmsBaseUrl}/activities`;
        try {
            return await Api.put(url, activity)
        } catch (e) {
            throw e
        }
    }

    patchActivity = async (activity) => {
        const url =  `${apiMmsBaseUrl}/activities`;
        try {
            return await Api.patch(url, activity)
        } catch (e) {
            throw e
        }
    }

    deleteActivity = async (id) => {
        try {
            return await Api.delete(`${apiMmsBaseUrl}/activities/${id}`)
        } catch (e) {
            throw e
        }
    }

    uploadFileToDepartment = async (departmentId, file) => {
        try {
            let fd = new FormData();
            fd.append('file', file)
            if (departmentId)
                return await Api.post(`${apiMmsBaseUrl}/files/by-departmentid/${departmentId}`, fd)
            else
                return await Api.post(`${apiMmsBaseUrl}/files/catalog`, fd)
        } catch (e) {
            throw e
        }
    }

    downloadFile = async (fileId) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/files/${fileId}/download`, null, {
                responseType: 'blob'
            })
        } catch (e) {
            throw e
        }
    }

    getTasksByDepartmentId = async (departmentId) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/activities/tasks/by-departmentid/${departmentId}`)
        } catch (e) {
            throw e
        }
    }

    getCatalogTasks = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/activities/tasks/catalog`)
        } catch (e) {
            throw e
        }
    }

    completeActivity = async (activity) => {
        try {
            return await Api.put(`${apiMmsBaseUrl}/activities/complete`, activity)
        } catch (e) {
            throw e
        }
    }

    completeActivityTask = async (replacedPartInfo) => {
        try {
            return await Api.put(`${apiMmsBaseUrl}/activities/tasks/complete`, replacedPartInfo)
        } catch (e) {
            throw e
        }
    }

    deleteTask = async (id) => {
        try {
            return await Api.delete(`${apiMmsBaseUrl}/activities/tasks/${id}`)
        } catch (e) {
            throw e
        }
    }

    getActivityParts = async (activityIds) => {
        let idParams = activityIds.map((c, index) => `activityIds[${index}]=${c}`).join('&')
        try {
            return await Api.get(`${apiMmsBaseUrl}/activities/parts?${idParams}`)
        } catch (e) {
            throw e
        }
    }

    createReport = async (report, departmentId) => {
        try {
            return await Api.post(`${apiMmsBaseUrl}/reports`, { ...report, departmentId: departmentId })
        } catch (e) {
            throw e
        }
    }

    getReports = async (departmentId) => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/reports/by-departmentid/${departmentId}`)
        } catch (e) {
            throw e
        }
    }

    updateReport = async (report) => {
        try {
            return await Api.put(`${apiMmsBaseUrl}/reports`, report)
        } catch (e) {
            throw e
        }
    }

    deleteReports = async (ids) => {
        try {
            return await Api.deleteWithBody(`${apiMmsBaseUrl}/reports`, { ids })
        } catch (e) {
            throw e
        }
    }

    setReportActivity = async (reportId, isActive) => {
        try {
            if (isActive)
                return await Api.put(`${apiMmsBaseUrl}/reports/${reportId}/activate`)
            else
                return await Api.put(`${apiMmsBaseUrl}/reports/${reportId}/deactivate`)
        } catch (e) {
            throw e
        }
    }

    exportReport = async (departmentId, filterInfo) => {

        try {
            const formattedFilterInfo = {
                status: filterInfo.statusId !== emptyConst ? filterInfo.statusId : null,
                filterDateType: filterInfo.dateRangeId !== emptyConst ? filterInfo.dateRangeId : null,
                componentId: filterInfo.componentId !== emptyConst ? filterInfo.componentId : null,
                priority: filterInfo.priorityId !== emptyConst ? filterInfo.priorityId : null,
                assigneeId: filterInfo.assigneeId !== emptyConst ? filterInfo.assigneeId : null,
                departmentId: departmentId,
                activityIds: filterInfo?.activityIds?.length ? filterInfo.activityIds : null,
            }
            return await Api.postBlob(`${apiMmsBaseUrl}/reports/export/activities/html`, formattedFilterInfo)
        } catch (e) {
            throw e;
        }
    }

    exportActivityParts = async (departmentId, activityIds) => {
        try {
            return await Api.postBlob(`${apiMmsBaseUrl}/reports/export/activity-parts`, { departmentId: departmentId, activityIds })
        } catch (e) {
            throw e
        }
    }

    exportRsp = async (departmentId, componentIds) => {
        try {
            return await Api.postBlob(`${apiMmsBaseUrl}/reports/export/spare-parts`, { departmentId: departmentId, componentIds: componentIds })
        } catch (e) {
            throw e
        }
    }

    getComponentsIntegrations = async () => {
        try {
            return await Api.get(`${apiMmsBaseUrl}/components/integrations`)
        } catch (e) {
            throw e
        }
    }
}

export default new MmsApiService()
