import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { MenuItem, Checkbox, Tooltip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import GetAppIcon from "@material-ui/icons/GetApp";
import MmsService from "Services/MMS/mms-api.service";
import DmsService from "Services/DMS/dms-api.service";
import {
  setComponents,
  setComponentsCatalog,
  setParts,
  setPartsCatalog,
} from "../../../../Store/modules/mms/actions";
import { connect } from "react-redux";
import { normalizeErrorResponse } from "utils/form";
import AlertToastr from "utils/alert";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import useTranslation from "Hooks/useTranslation";
import { useQueryClient } from "react-query";
import { Autocomplete } from "@material-ui/lab";
import Api from "../../../../Services/Api.service";
import { apiMmsBaseUrl } from "config";
import { useAuth } from "../../../../Contexts/AuthProvider";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const BlankComponentData = {
  parentComponentId: "",
  name: "",
  number: "",
  uId: "",
  supplierName: "",
  supplierPartNumber: "",
  supplierDeliveryTime: "",
  manufacturerName: "",
  manufacturerPartNumber: "",
  manufacturerType: "",
  workhoursUpdateType: "",
  workhoursTotal: 0,
  offset: 0,
  workhoursFactor: 1,
  dimensionUnit: "",
  dmsUnitUId: "",
  channelCode: "",
  length: "",
  width: "",
  height: "",
  weightUnit: "",
  weight: "",
  sparePartIds: {},
  fileIds: [],
  files: [],
  syncWorkhours: true
};
const AmIcon6 = () => <div className="amPlIcon" />;

const SaveComponentModal = (props) => {
  const {
    open,
    handleClose,
    component,
    currentDepartment,
    parentComponentId,
    components,
    setComponents,
    isCatalog,
    isUnassignedComponent,
    editUnassignedComponents,
    componentsCatalog,
    setComponentsCatalog,
    parts,
    setParts,
    partsCatalog,
    setPartsCatalog,
    openSubcomponents,
    isReadOnly,
    loading,
    duplicateBasedOnComponent,
    onSuccessSave,
    compDetails,
    setExpandedIds,
    openedComponentsIds,
    unussigned,
    showSyncWorkhours
  } = props;

  const translation = useTranslation();
  const emptyCatalogComponent = useMemo(() => {
    return {
      id: "empty",
      name: translation.saveComponentModalSelectFromCatalogOption,
    };
  }, [translation]);
  const [showDimensionBlock] = useState(false);
  const [dimensionsOptions, setDimensionsOptions] = useState([]);
  const [weightOptions, setWeightOptions] = useState([]);
  const [updateTypesOptions, setUpdateTypesOptions] = useState([]);
  const [componentInfo, setComponentInfo] = useState(BlankComponentData);
  const [disableButtons, setDisabledButtons] = useState(false);
  const [selectedCatalogComponent, setSelectedCatalogComponent] = useState(
    () => emptyCatalogComponent
  );
  const [partsMenuAnchorEl, setPartsMenuAnchorElChange] = useState(null);
  const [partList, setPartList] = useState(null);
  const inputFile = useRef(null);
  const [errors, setErrors] = useState({});
  const [units, setUnits] = useState([]);
  const [channels, setChannels] = useState([]);
  const queryClient = useQueryClient();

  const emptyComponentOption = useMemo(() => {
    return { id: "empty", name: translation.saveComponentModalSelectUCOption };
  }, [translation]);

  const [selectedAutocompleteComponent, setSelectedAutocompleteComponent] =
    useState(emptyComponentOption);

  const { currentCompany } = useAuth();
  const { currentUser } = useAuth();
  const isSuperUser = useMemo(() => currentUser.isSuperuser(), [currentUser]);
  const [unassignedComponents, setUnassignedComponents] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); 
  const [selectedOption, setSelectedOption] = useState('createNew');
  const [isVisibleTemplate, setIsVisibleTemplate] = useState(false);
  const [isVisibleUnassigned, setIsVisibleUnassigned] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);

    if (e.target.value === 'selectTemplate') {
      setIsVisibleTemplate(true);
      setIsVisibleUnassigned(false);
    } else if (e.target.value === 'selectUnassigned') {
      setIsVisibleTemplate(false);
      setIsVisibleUnassigned(true);
    } else {
      setIsVisibleTemplate(false);
      setIsVisibleUnassigned(false);
    }
  };

  const showUnassignedComponents = (parentComponentId && !duplicateBasedOnComponent) && !isUnassignedComponent;


  const emptyUnitOption = useMemo(() => {
    return { id: "empty", name: translation.saveComponentModalSelectUnitLabel };
  }, [translation]);
  const emptyChannelOption = useMemo(() => {
    return {
      id: "empty",
      name: translation.saveComponentModalSelectChannelLabel,
    };
  }, [translation]);

  const isComponent = useMemo(() => {
    if (parentComponentId) return true;

    return !!(component && component.parentComponentId);
  }, [parentComponentId, component]);

  useEffect(() => {
    const fetchDimensionsOptions = async () => {
      let response = await MmsService.getDimensionsOptions();
      setDimensionsOptions(response.data);
      if (response.data[0] && !component) {
        setComponentInfo((componentInfo) => {
          return { ...componentInfo, dimensionUnit: response.data[0] };
        });
      }
    };
    const fetchUnits = async () => {
      let response = await DmsService.getUnits();
      setUnits(response.data);
      if (component && component.dmsUnitUId)
        setChannels(
          response.data.find((x) => x.uId === component.dmsUnitUId)
            ? response.data.find((x) => x.uId === component.dmsUnitUId).channels
            : ""
        );
    };
    const fetchWeightOptions = async () => {
      let response = await MmsService.getWeightOptions();
      setWeightOptions(response.data);
      if (response.data[0] && !component) {
        setComponentInfo((componentInfo) => {
          return { ...componentInfo, weightUnit: response.data[0] };
        });
      }
    };
    const fetchUpdateTypesOptions = async () => {
      let response = await MmsService.getUpdateTypesOptions();
      let filtredUpdateTypes = [];
      if (
        (parentComponentId && !component) ||
        (component && component.parentComponentId) ||
        duplicateBasedOnComponent?.parentComponentId
      )
        filtredUpdateTypes = response.data;
      else
        filtredUpdateTypes = response.data.filter(
          (x) => x.updateType !== "parent"
        );

      setUpdateTypesOptions(filtredUpdateTypes);
      if (filtredUpdateTypes[0] && !component && !duplicateBasedOnComponent) {
        const defaultType = filtredUpdateTypes.some(
          (x) => x.updateType === "parent"
        )
          ? "parent"
          : filtredUpdateTypes[0].updateType;
        setComponentInfo((componentInfo) => {
          return {
            ...componentInfo,
            workhoursUpdateType: isUnassignedComponent ? "manual" : defaultType,
          };
        });
      }
    };
    const fetchComponentsCatalog = async () => {
      try {
        const response = await MmsService.getComponentsCatalog();
        setComponentsCatalog(response.data);
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          AlertToastr.showErrorAlert(error.response.data.message);
      }
    };
    if (!open) return;
    fetchDimensionsOptions();
    fetchWeightOptions();
    fetchUpdateTypesOptions();
    fetchUnits();

    if (!component && (!isCatalog || parentComponentId))
      fetchComponentsCatalog();
  }, [
    open,
    component,
    setComponentsCatalog,
    currentDepartment,
    isCatalog,
    parentComponentId,
    duplicateBasedOnComponent,
    isUnassignedComponent,
  ]);

  useEffect(() => {
    const fetchParts = async () => {
      try {
        const response = await MmsService.getPartsList();
        setPartList(response.data);
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          AlertToastr.showErrorAlert(error.response.data.message);
      }
    };
    const fetchPartsCatalog = async () => {
      try {
        const response = await MmsService.getPartsCatalog();
        setPartList(response.data);
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        )
          AlertToastr.showErrorAlert(error.response.data.message);
      }
    };
    if (!open) return;
    if (isCatalog) {
      if (partsCatalog) setPartList(partsCatalog);
      else fetchPartsCatalog();
    } else {
      if (parts) setPartList(parts);
      else fetchParts();
    }
  }, [
    setParts,
    parts,
    setPartsCatalog,
    partsCatalog,
    currentDepartment,
    open,
    isCatalog,
  ]);

  const fetchComponentsWithState = useCallback(async () => {
    if (currentCompany?.id) {
      try {
        const headers = isSuperUser ? { CompanyId: currentCompany?.id } : {};
        const stateListQuery = await Api.get(
          `${apiMmsBaseUrl}/components/unassigned/state/fieldReady`,
          { headers }
        );

        if (stateListQuery.data) {
          setUnassignedComponents(stateListQuery.data);
        }
      } catch (error) {
        console.error("Error fetching components:", error);
      }
    } else {
      try {
        const stateListQuery = await Api.get(
          `${apiMmsBaseUrl}/components/unassigned/state/fieldReady`
        );

        if (stateListQuery.data) {
          setUnassignedComponents(stateListQuery.data);
        }
      } catch (error) {
        console.error("Error fetching components:", error);
      }
    }
  }, [currentCompany?.id, isSuperUser]);

  useEffect(() => {
    if (!open) return;
    fetchComponentsWithState();
  }, [fetchComponentsWithState, open]);

  useEffect(() => {
    if (!open) return;

    if (!duplicateBasedOnComponent) {
      setSelectedCatalogComponent(emptyCatalogComponent);
    }
    if (component) {
      if (!component.sparePartIds) component.sparePartIds = {};
      if (!component.fileIds) component.fileIds = [];
      if (!component.files) component.files = [];
      setComponentInfo(JSON.parse(JSON.stringify({ ...component })));
    } else if (duplicateBasedOnComponent) {
      const newComponent = { ...duplicateBasedOnComponent };
      delete newComponent.id;
      newComponent["number"] = "";
      newComponent["uId"] = "";
      newComponent["parentComponentId"] = newComponent?.parentComponentId;
      newComponent["workhoursUpdateType"] = newComponent?.parentComponentId
        ? "parent"
        : "manual"; // 'sync'
      setComponentInfo(newComponent);
      setSelectedCatalogComponent({ ...duplicateBasedOnComponent });
    } else {
      setComponentInfo({
        ...BlankComponentData,
        sparePartIds: {},
        fileIds: [],
        files: [],
      });
    }
  }, [component, open, emptyCatalogComponent, duplicateBasedOnComponent]);

  const selectedCatalogComponentChanged = (selectedComponent) => {
    setSelectedCatalogComponent(selectedComponent);
    if (selectedComponent.id === emptyCatalogComponent.id) {
      setComponentInfo(BlankComponentData);
    } else {
      if (!selectedComponent.sparePartIds) selectedComponent.sparePartIds = {};
      if (!selectedComponent.fileIds) selectedComponent.fileIds = [];
      if (!selectedComponent.files) selectedComponent.files = [];
      if (
        !updateTypesOptions.some(
          (x) => x.updateType === selectedComponent.workhoursUpdateType
        )
      ) {
        selectedComponent.workhoursUpdateType = "manual";
      }
      setComponentInfo(selectedComponent);
    }
  };

  const selectedUnassignedComponentChanged = (selectedComponent) => {
    setSelectedAutocompleteComponent(selectedComponent);
    if (selectedComponent.id === emptyCatalogComponent.id) {
      setComponentInfo(BlankComponentData);
    } else {
      selectedComponent.parentComponentId = parentComponentId;
      selectedComponent.departmentId = currentDepartment.id;
      selectedComponent.componentId = selectedComponent.id;
      setComponentInfo(selectedComponent);
    }
  };

  const onSave = () => {
    setDisabledButtons(true);
    setSelectedOption('createNew')
    if (component) {
      MmsService.updateComponent(componentInfo)
        .then(async (response) => {
          if (response.data.isSuccessful) {
            if (isCatalog) {
              const response = await MmsService.getComponentsCatalog();
              setComponentsCatalog(response.data);
            }
            queryClient.invalidateQueries(["components"]).then();
            queryClient.invalidateQueries(["unassigned"]).then();
            if (response.data.expandedIds.length > 0) {
              setExpandedIds(response.data.expandedIds);
            } else {
              setExpandedIds(openedComponentsIds);
            }
            if (response?.data?.message) {
              AlertToastr.showWarnAlert(response.data.message);
            }
            handleClose();
            setErrors({});
            setDisabledButtons(false);
          }
          if (response.data.item.parentComponentId) {
            AlertToastr.showAlert(translation.notificationsComponentUpdated);
          } else {
            AlertToastr.showAlert(translation.notificationsAssetUpdated);
          }
        })
        .catch((error) => {
          if (!error || !error.response || !error.response.data) return;
          const resErrors = normalizeErrorResponse(error.response.data);
          if (resErrors.errors) setErrors(resErrors.errors);
          setDisabledButtons(false);
          if (resErrors && resErrors.message)
            AlertToastr.showErrorAlert(resErrors.message);
          if (
            error.response.data.errors &&
            Array.isArray(error.response.data.errors)
          )
            error.response.data.errors
              .filter((x) => x.PropertyName === "")
              .map((x) => {
                AlertToastr.showErrorAlert(x.ErrorMessage);
                return x;
              });
        });
    } else {
      const departmentId = isCatalog ? null : currentDepartment.id;
      if (
        selectedCatalogComponent.id === emptyCatalogComponent.id &&
        !unussigned &&
        selectedAutocompleteComponent === emptyComponentOption
      ) {
        MmsService.createComponent(
          componentInfo,
          departmentId,
          parentComponentId
        )
          .then(async (response) => {
            if (response.data.isSuccessful) {
              if (parentComponentId) {
                AlertToastr.showAlert(translation.notificationsComponentAdded);
                onSuccessSave(response.data.item);
                queryClient
                  .invalidateQueries(["components", departmentId])
                  .then();
                setExpandedIds(response.data.expandedIds);
              } else {
                AlertToastr.showAlert(translation.notificationsAssetAdded);
                queryClient
                  .invalidateQueries(["components", departmentId])
                  .then();
              }
              if (isCatalog) {
                const response = await MmsService.getComponentsCatalog();
                setComponentsCatalog(response.data);
              }
              if (response?.data?.message) {
                AlertToastr.showWarnAlert(response.data.message);
              }
              handleClose();
              setErrors({});
              setDisabledButtons(false);
              setSelectedCatalogComponent(null)
            }
          })
          .catch((error) => {
            if (!error || !error.response || !error.response.data) return;
            const resErrors = normalizeErrorResponse(error.response.data);
            if (resErrors.errors) setErrors(resErrors.errors);
            setDisabledButtons(false);
            if (resErrors && resErrors.message)
              AlertToastr.showErrorAlert(resErrors.message);
            if (error.response.data.errors)
              error.response.data.errors
                .filter((x) => x.PropertyName === "")
                .map((x) => {
                  AlertToastr.showErrorAlert(x.ErrorMessage);
                  return x;
                });
          });
      } else if (
        !unussigned &&
        selectedAutocompleteComponent === emptyComponentOption
      ) {
        const parentId = parentComponentId ?? componentInfo?.parentComponentId;
        MmsService.cloneModifiedComponent(
          selectedCatalogComponent.id,
          isCatalog ? null : currentDepartment.id,
          parentId,
          componentInfo
        )
          .then(async (response) => {
            if (response.data.isSuccessful) {
              queryClient
                .invalidateQueries(["components", departmentId])
                .then();
              if (response.data.message) {
                AlertToastr.showWarnAlert(response.data.message);
              }
              if (response.data.expandedIds.length > 0) {
                setExpandedIds(response.data.expandedIds);
              } else {
                setExpandedIds(openedComponentsIds);
              }
              AlertToastr.showAlert(translation.notificationsComponentCloned);
              handleClose();
              setErrors({});
              if (isCatalog) {
                const response = await MmsService.getComponentsCatalog();
                setComponentsCatalog(response.data);
              } else {
                const response = await MmsService.getComponents(departmentId);
                setComponents(response.data);
              }
              if (parentComponentId) {
                openSubcomponents(parentComponentId);
              }
            }
            setDisabledButtons(false);
          })
          .catch((error) => {
            if (!error || !error.response || !error.response.data) return;
            console.log(error);
            const resErrors = normalizeErrorResponse(error.response.data);
            if (resErrors.errors) setErrors(resErrors.errors);
            setDisabledButtons(false);
            if (resErrors && resErrors.message)
              AlertToastr.showErrorAlert(resErrors.message);
            if (error.response.data.errors)
              error.response.data.errors
                .filter((x) => x.PropertyName === "")
                .map((x) => {
                  AlertToastr.showErrorAlert(x.ErrorMessage);
                  return x;
                });
          });
      }
      if (unussigned) {
        MmsService.createUnassignedComponent(componentInfo, parentComponentId === false)
          .then(async (response) => {
            if (response.data.isSuccessful) {
              AlertToastr.showAlert(translation.notificationsComponentAdded);
              onSuccessSave(response.data.item);
              handleClose();
              setDisabledButtons(false);
              queryClient.invalidateQueries(["unassigned"]).then();
              setComponentInfo((componentInfo) => {
                return { ...componentInfo, number: "" };
              });
            }
          })
          .catch((error) => {
            if (!error || !error.response || !error.response.data) return;
            const resErrors = normalizeErrorResponse(error.response.data);
            if (resErrors.errors) setErrors(resErrors.errors);
            setDisabledButtons(false);
            if (resErrors && resErrors.message)
              AlertToastr.showErrorAlert(resErrors.message);
            if (error.response.data.errors)
              error.response.data.errors
                .filter((x) => x.PropertyName === "")
                .map((x) => {
                  AlertToastr.showErrorAlert(x.ErrorMessage);
                  return x;
                });
          });
      }
      if (
        selectedAutocompleteComponent !== emptyComponentOption
      ) {
        MmsService.addUnassignedComponent(
          componentInfo
        )
          .then(async (response) => {
            if (response.data.isSuccessful) {
              AlertToastr.showAlert(translation.notificationsComponentAdded);
              onSuccessSave(response.data.item);
              handleClose();
              setSelectedAutocompleteComponent(emptyComponentOption)
              setExpandedIds(response.data.expandedIds);
              setComponentInfo((componentInfo) => {
                return { ...componentInfo };
              });
              handleClose();
              queryClient.invalidateQueries(["components"]).then();
            }
            setDisabledButtons(false);
            setSelectedAutocompleteComponent(emptyComponentOption)
            handleClose();
          })
          .catch((error) => {
            if (!error || !error.response || !error.response.data) return;
            const resErrors = normalizeErrorResponse(error.response.data);
            if (resErrors.errors) setErrors(resErrors.errors);
            setDisabledButtons(false);
            if (resErrors && resErrors.message)
              AlertToastr.showErrorAlert(resErrors.message);
            if (error.response.data.errors)
              error.response.data.errors
                .filter((x) => x.PropertyName === "")
                .map((x) => {
                  AlertToastr.showErrorAlert(x.ErrorMessage);
                  return x;
                });
          });
      }
    }
  };

  const onPartAdded = (part) => {
    if (componentInfo.sparePartIds[part.id]) {
      const newSparePartIds = { ...componentInfo.sparePartIds };
      delete newSparePartIds[part.id];
      setComponentInfo({ ...componentInfo, sparePartIds: newSparePartIds });
    } else {
      const newSparePartIds = { ...componentInfo.sparePartIds, [part.id]: 1 };
      setComponentInfo({ ...componentInfo, sparePartIds: newSparePartIds });
    }
  };

  const changePartItemQty = (partId, qty) => {
    setComponentInfo((prevComponentInfo) => ({
      ...prevComponentInfo,
      sparePartIds: {
        ...prevComponentInfo.sparePartIds,
        [partId]: qty,
      },
    }));
  };

  const onFileSelected = (file) => {
    const departmentId = isCatalog ? null : currentDepartment.id;
    MmsService.uploadFileToDepartment(departmentId, file)
      .then((response) => {
        if (response.data.isSuccessful) {
          setComponentInfo({
            ...componentInfo,
            fileIds: [...componentInfo.fileIds, response.data.item.id],
            files: [
              ...componentInfo.files,
              {
                id: response.data.item.id,
                name: response.data.item.name,
                url: response.data.item.url,
              },
            ],
          });
        }
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) return;
        const resErrors = normalizeErrorResponse(error.response.data);
        setDisabledButtons(false);
        if (resErrors && resErrors.message)
          AlertToastr.showErrorAlert(resErrors.message);
        if (error.response.data.errors)
          error.response.data.errors
            .filter((x) => x.PropertyName === "")
            .map((x) => {
              AlertToastr.showErrorAlert(x.ErrorMessage);
              return x;
            });
      });
  };

  // const downloadFile = (fileId) => {
  //   MmsService.downloadFile(fileId).then(response => {
  //     const filename = componentInfo.files.find(p => p.id === fileId).name
  //     const element = document.createElement('a')
  //     const file = new Blob([response.data], { type: 'application/octet-stream' })
  //     element.href = URL.createObjectURL(file)
  //     element.download = filename
  //     element.click()
  //   }).catch(error => {
  //     if (error && error.response && error.response.data && error.response.data.message)
  //       AlertToastr.showErrorAlert(error.response.data.message)
  //   })
  // }

  const modalTitle = () => {
    if (duplicateBasedOnComponent) {
      return duplicateBasedOnComponent.parentComponentId
        ? translation.duplicateComponentModalAddChildComponentLabel
        : translation.duplicateComponentModalAddComponentLabel;
    }

    if (component) {
      return component.parentComponentId || compDetails
        ? translation.saveComponentModalEditChildComponentLabel
        : translation.saveComponentModalEditComponentLabel;
    }

    return parentComponentId || isUnassignedComponent
      ? translation.saveComponentModalAddChildComponentLabel
      : translation.saveComponentModalAddComponentLabel;
  };

  const sparePartsCount = Object.entries(componentInfo.sparePartIds).reduce(
    (sum, currentItem) => {
      const [, qty] = currentItem;

      return sum + parseInt(qty) || 0;
    },
    0
  );

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue); 
  };

  const renderDimBlock = () => {
    if (!showDimensionBlock) return null;

    return (
      <div className="formContentBlock">
        <div className="halfBlockContainer">
          <div className="halfBlock">
            <FormLabel component="legend" className="mb5">
              {translation.saveComponentModalDimensionsLabel}
            </FormLabel>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={
                errors && errors.dimensionUnit
                  ? errors.dimensionUnit.join("\n")
                  : ""
              }
            >
              <div>
                <TextField
                  disabled={isReadOnly}
                  value={componentInfo.dimensionUnit}
                  onChange={(e) => {
                    if (errors.dimensionUnit) errors.dimensionUnit = null;
                    setComponentInfo({
                      ...componentInfo,
                      dimensionUnit: e.target.value,
                    });
                  }}
                  error={
                    errors &&
                    errors.dimensionUnit &&
                    errors.dimensionUnit.length > 0
                  }
                  variant="outlined"
                  margin="normal"
                  select
                  fullWidth
                >
                  {dimensionsOptions &&
                    dimensionsOptions.map((dimensionsOption) => (
                      <MenuItem
                        key={dimensionsOption}
                        value={dimensionsOption}
                        className="filterMenuItem"
                      >
                        {dimensionsOption}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
            </Tooltip>
            <div className="textFieldGroup">
              <Tooltip
                disableFocusListener
                disableTouchListener
                title={errors && errors.length ? errors.length.join("\n") : ""}
              >
                <div>
                  <div className="tfgItem">
                    <TextField
                      disabled={isReadOnly}
                      type="number"
                      value={componentInfo.length}
                      onChange={(e) => {
                        if (errors.length) errors.length = null;
                        setComponentInfo({
                          ...componentInfo,
                          length: e.target.value,
                        });
                      }}
                      error={
                        errors && errors.length && errors.length.length > 0
                      }
                      variant="outlined"
                      margin="normal"
                      label={translation.saveComponentModalLengthLabel}
                      fullWidth
                    />
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                disableFocusListener
                disableTouchListener
                title={errors && errors.width ? errors.width.join("\n") : ""}
              >
                <div>
                  <div className="tfgItem">
                    <TextField
                      disabled={isReadOnly}
                      type="number"
                      value={componentInfo.width}
                      onChange={(e) => {
                        if (errors.width) errors.width = null;
                        setComponentInfo({
                          ...componentInfo,
                          width: e.target.value,
                        });
                      }}
                      error={errors && errors.width && errors.width.length > 0}
                      variant="outlined"
                      margin="normal"
                      label={translation.saveComponentModalWidthLabel}
                      fullWidth
                    />
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                disableFocusListener
                disableTouchListener
                title={errors && errors.height ? errors.height.join("\n") : ""}
              >
                <div>
                  <div className="tfgItem">
                    <TextField
                      disabled={isReadOnly}
                      type="number"
                      value={componentInfo.height}
                      onChange={(e) => {
                        if (errors.height) errors.height = null;
                        setComponentInfo({
                          ...componentInfo,
                          height: e.target.value,
                        });
                      }}
                      error={
                        errors && errors.height && errors.height.length > 0
                      }
                      variant="outlined"
                      margin="normal"
                      label={translation.saveComponentModalHeightLabel}
                      fullWidth
                    />
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="halfBlock">
            <FormLabel component="legend" className="mb5">
              {translation.saveComponentModalWeightLabel}
            </FormLabel>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={
                errors && errors.weightUnit ? errors.weightUnit.join("\n") : ""
              }
            >
              <div>
                <TextField
                  disabled={isReadOnly}
                  value={componentInfo.weightUnit}
                  onChange={(e) => {
                    if (errors.weightUnit) errors.weightUnit = null;
                    setComponentInfo({
                      ...componentInfo,
                      weightUnit: e.target.value,
                    });
                  }}
                  error={
                    errors && errors.weightUnit && errors.weightUnit.length > 0
                  }
                  variant="outlined"
                  margin="normal"
                  select
                  fullWidth
                >
                  {weightOptions &&
                    weightOptions.map((weightOption) => (
                      <MenuItem
                        key={weightOption}
                        value={weightOption}
                        className="filterMenuItem"
                      >
                        {weightOption}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
            </Tooltip>
            <div className="textFieldGroup">
              <div className="tfgItem">
                <Tooltip
                  disableFocusListener
                  disableTouchListener
                  title={
                    errors && errors.weight ? errors.weight.join("\n") : ""
                  }
                >
                  <div>
                    <TextField
                      disabled={isReadOnly}
                      type="number"
                      value={componentInfo.weight}
                      onChange={(e) => {
                        if (errors.weight) errors.weight = null;
                        setComponentInfo({
                          ...componentInfo,
                          weight: e.target.value,
                        });
                      }}
                      error={
                        errors && errors.weight && errors.weight.length > 0
                      }
                      variant="outlined"
                      margin="normal"
                      label={translation.saveComponentModalWeightPlaceholder}
                      fullWidth
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const renderSyncWorkhoursCheckbox = () => {
    if(!showSyncWorkhours) return null;
    if(unussigned) return null;
    if(parentComponentId) return null;
    if(component?.parentComponentId || compDetails) return null;
    
      return (
       <div className="formContentBlock modalFieldLabelSettings">
        <FormLabel component="legend" className="mb5 modalFieldLabel">
              API integration
        </FormLabel>
        <Tooltip
          disableFocusListener
          disableTouchListener
          title={
            errors && errors.isCheckedOnDemand
              ? errors.isCheckedOnDemand.join("\n")
              : ""
          }
        >
        <div
          className={`formCheckBoxHolder ${
            errors &&
            errors.isCheckedOnDemand &&
            errors.isCheckedOnDemand.length > 0
              ? "hasError"
              : ""
          }`}
          style={{ display: "flex" }}
        >
          <FormControlLabel
            style={{ marginRight: "0", marginLeft: '-10px' }}
            control={
              <Checkbox
                checked={componentInfo.syncWorkhours}
                onChange={(e) =>
                  setComponentInfo({
                    ...componentInfo,
                    syncWorkhours: e.target.checked,
                  })
                }
                id="assetsAddComponentChangeSyncWorkhoursCheckbox"
                name="onDemand"
                size="small"
                color="default"
                // className={
                //   activityInfo.isCheckedOnDemand ? "checkedCheckboxLabel" : ""
                // }
              />
            }
            label={""}
          />
          <span
            className={ 
               "checkedCheckboxLabel"
                // : "сheckboxLabel"
            }
          >
            {translation.saveComponentModalSyncWorkhoursLabel}
          </span>
        </div>
      </Tooltip>
       </div>
      )
  }
    
  

  const renderRecommendedPartsBlock = () => {
    return (
      <div>
        <div className="wrapper content-justified align-center mb5 modalFieldLabelSettings">
          <FormLabel
            component="legend"
            disabled={isReadOnly}
            className="modalFieldLabel"
          >{`${translation.saveComponentModalRecommendedSparePartsLabel} (${sparePartsCount})`}</FormLabel>
          <Button
            disabled={
              isReadOnly ||
              !partList ||
              partList?.length === 0 ||
              selectedAutocompleteComponent !== emptyComponentOption
            }
            onClick={(e) => {
              setPartsMenuAnchorElChange(e.target);
            }}
            startIcon={<AmIcon6 />}
            id="assetsAddComponentChangeRecommendedPartsBtn"
          >
            {translation.saveComponentModalAddButtonLabel}
          </Button>
        </div>
        <ul className={`partsListGroup ${isReadOnly ? "disabled" : ""}`}>
          {partList &&
            Object.entries(componentInfo.sparePartIds).map(
              ([partId, partQty]) => (
                <li key={partId}>
                  <div className="name">
                    {partList.find((p) => p.id === partId)
                      ? `${partList.find((p) => p.id === partId).fullName} 
                       
                        `
                      : `${
                          componentInfo.spareParts.find((p) => p.id === partId)
                            .fullName
                        } (${
                          componentInfo.spareParts.find((p) => p.id === partId)
                            .number
                        })`}
                  </div>
                  <div className="btnPart">
                    <TextField
                      inputProps={{
                        style: {
                          padding: "0 5px",
                          height: "1.5rem",
                        },
                      }}
                      style={{ width: 100 }}
                      size="small"
                      disabled={
                        isReadOnly ||
                        selectedAutocompleteComponent !== emptyComponentOption
                      }
                      type="number"
                      value={partQty}
                      onChange={(e) =>
                        changePartItemQty(partId, e.target.value)
                      }
                      error={
                        errors &&
                        errors[`sparePartIds.${partId}`] &&
                        errors[`sparePartIds.${partId}`].length > 0
                      }
                      variant="outlined"
                      fullWidth
                      placeholder={
                        translation.activitiesPageTasksTableQuantityLabel
                      }
                    />

                    <IconButton
                      className="delete"
                      size="small"
                      style={{ color: "#b1b3b5" }}
                      id="assetsAddComponentDeleteRecommendedPartsBtn"
                      onClick={(e) => {
                        if (selectedCatalogComponent.id)
                          // setComponentInfo({
                          //     ...componentInfo,
                          //     sparePartIds: componentInfo.sparePartIds.filter(p => p !== partId),
                          // })

                          setComponentInfo((prevComponentInfo) => {
                            const newSparePartIds = {
                              ...prevComponentInfo.sparePartIds,
                            };
                            delete newSparePartIds[partId];
                            return {
                              ...prevComponentInfo,
                              sparePartIds: newSparePartIds,
                            };
                          });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                </li>
              )
            )}
        </ul>
      </div>
    );
  };

  const renderFileUploadBlock = () => {
    return (
      <div>
        <div className="wrapper content-justified align-center mb5 modalFieldLabelSettings">
          <FormLabel
            component="legend"
            disabled={isReadOnly}
            className="modalFieldLabel"
          >{`${translation.saveComponentModalUserManualsLabel} (${componentInfo.fileIds.length})`}</FormLabel>
          <Button
            disabled={
              isReadOnly ||
              selectedAutocompleteComponent !== emptyComponentOption
            }
            onClick={(e) => inputFile.current.click()}
            startIcon={<AmIcon6 />}
            id="assetsAddComponentModalUploadFileBtn"
          >
            {translation.saveComponentModalUploadButtonLabel}
          </Button>
          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={(e) => {
              if (!e.target.files[0]) return;
              onFileSelected(e.target.files[0]);
            }}
          />
        </div>
        <ul className={`partsListGroup ${isReadOnly ? "disabled" : ""}`}>
          {componentInfo.fileIds &&
            componentInfo.fileIds.map((fileId) => {
              const file = componentInfo.files.find((p) => p.id === fileId);
              return (
                <li key={fileId}>
                  <div className="name">
                    {componentInfo.files.find((p) => p.id === fileId).name}
                  </div>
                  <div className="btnPart">
                    <IconButton
                      className="delete"
                      size="small"
                      style={{ color: "#b1b3b5" }}
                      id="assetsAddComponentModalDownloadFileBtn"
                      onClick={(e) => {
                        fetch(file.url)
                          .then((response) => response.blob())
                          .then((blob) => {
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.download = file.name;
                            link.click();
                          });
                      }}
                    >
                      <GetAppIcon />
                    </IconButton>

                    <IconButton
                      className="delete"
                      size="small"
                      disabled={isReadOnly}
                      style={{ color: "#b1b3b5" }}
                      id="assetsAddComponentModalDeleteFileBtn"
                      onClick={(e) => {
                        if (selectedCatalogComponent.id)
                          setComponentInfo({
                            ...componentInfo,
                            fileIds: componentInfo.fileIds.filter(
                              (p) => p !== fileId
                            ),
                          });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    );
  };

  const renderTemplateSelector = () => {
    if (parentComponentId) return null;
    if (duplicateBasedOnComponent) return null;

    if (
      !component &&
      !component &&
      (!isCatalog || parentComponentId) &&
      !isUnassignedComponent
    ) {
      return (
        <div className="formContentBlock modalFieldLabelSettings" style={{paddingTop: "0", paddingBottom: '0'}}>
          {/* <FormLabel component="legend" className="mb5 modalFieldLabel">
            {translation.saveComponentModalChooseFromCatalogLabel}
          </FormLabel> */}
          <TextField
            value={selectedCatalogComponent}
            onChange={(e) => selectedCatalogComponentChanged(e.target.value)}
            variant="outlined"
            margin="normal"
            select
            fullWidth
          >
            <MenuItem
              key={emptyCatalogComponent.id}
              value={emptyCatalogComponent}
              className="filterMenuItem"
              id="assetsAddBtnModalChangeTemplate"
            >
              {emptyCatalogComponent.name}
            </MenuItem>
            {!isCatalog && (
              <MenuItem disabled key="equipment" className="filterMenuItem">
                {translation.saveComponentModalEquipmentOption}
              </MenuItem>
            )}
            {!isCatalog &&
              components &&
              components
                .filter((i) => !i.parentComponentId)
                .sort((a, b) =>
                  a.fullName?.toLowerCase() < b.fullName?.toLowerCase() ? -1 : 1
                )
                .filter((c) => c.id !== parentComponentId && c.isActive)
                .map((component) => {
                  return (
                    <MenuItem
                      key={component.id}
                      id="assetsAddBtnModalChooseTemplate"
                      value={component}
                      className="filterMenuItem"
                    >
                      {component.fullName}
                    </MenuItem>
                  );
                })}
            {!isCatalog && (
              <MenuItem disabled key="catalog" className="filterMenuItem">
                {translation.saveComponentModalCatalogOption}
              </MenuItem>
            )}
            {componentsCatalog &&
              componentsCatalog
                .filter((i) => !i.parentComponentId)
                .sort((a, b) =>
                  a.fullName.toLowerCase() < b.fullName.toLowerCase() ? -1 : 1
                )
                .filter((c) => c.id !== parentComponentId && c.isActive)
                .map((component) => (
                  <MenuItem
                    key={component.id}
                    value={component}
                    className="filterMenuItem"
                  >
                    {component.fullName}
                  </MenuItem>
                ))}
          </TextField>
        </div>
      );
    }

    return null;
  };

  const renderUnassignedComponentSelector = () => {
    if (
      (parentComponentId && !duplicateBasedOnComponent) &&
      !isUnassignedComponent
    ) {
      return (
        <div className="formContentBlock modalFieldLabelSettings">
          {/* <FormLabel component="legend" className="mb5 modalFieldLabel">
            {translation.saveComponentModalChooseFromUCLabel}
          </FormLabel> */}
          <Autocomplete
            id="assetsTblMenuAddComponentModalSelectUcComponent"
            className="autocompleteActivityModal"
            options={[emptyComponentOption, ...unassignedComponents]}
            getOptionLabel={(option) =>
              option.uId ? `${option.name} (${option.uId})` : option.name
            }
            onChange={(event, value) => selectedUnassignedComponentChanged(value)}
            value={selectedAutocompleteComponent}
            renderInput={(params) => (
              <TextField
                {...params}
                id="replaceModalNewSelectComponentChoose"
                // label="Select unassigned component"
                variant="outlined"
                margin="normal"
                fullWidth
                className="mb16"
              />
            )}
          />
        </div>
      );
    }
  };
 

  const renderRadioButtons = (selectedOption, handleOptionChange, showUnassignedComponents) => {
    if (component) return null;
    if (duplicateBasedOnComponent) return null;
    return (
      <div className="radioButtonGroup">
        <label className="radioButtonLabel">
          <input
            type="radio"
            value="createNew"
            checked={selectedOption === 'createNew'}
            onChange={handleOptionChange}
            id="createNewAssetRadioBtn"
          />
          Create New
        </label>
        <label className="radioButtonLabel">
          <input
            type="radio"
            value={showUnassignedComponents ? 'selectUnassigned' : 'selectTemplate'}
            checked={selectedOption === (showUnassignedComponents ? 'selectUnassigned' : 'selectTemplate')}
            onChange={handleOptionChange}
            id="selectFromTemplateAssetRadioBtn"
          />
          {showUnassignedComponents ? 'Choose From Unassigned Components' : 'Copy From Templates or Existing Assets'}
        </label>
      </div>
    );
  };
  

  const componentTextFieldLabel =
    isComponent || isUnassignedComponent
      ? translation.saveComponentModalComponentComponentLabel
      : translation.saveComponentModalComponentLabel;

  if (loading) return null;

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={(e) => {
          handleClose();
          setErrors({});
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onSave();
          }
        }}
        className="respModal scrollContentModal autoHeight"
      >
        <div className="formContentHolder">
          <div className="modalTitleHolder">
            <h1 className="modalTitle text-center">{modalTitle()} </h1>
          </div>
          <div className="formContent">
          {renderRadioButtons(selectedOption, handleOptionChange, showUnassignedComponents)}
          <div className={`slide ${isVisibleTemplate && !duplicateBasedOnComponent ? 'show' : 'hide'}`}>
              {renderTemplateSelector()}
            </div>

            <div className={`slide ${isVisibleUnassigned && !duplicateBasedOnComponent ? 'showUnassigned' : 'hide'}`}>
              {renderUnassignedComponentSelector()}
            </div>
            <div className="formContentBlock modalFieldLabelSettings">
              <FormLabel
                component="legend"
                disabled={isReadOnly}
                className="mb5 modalFieldLabel"
              >
                {componentTextFieldLabel}
              </FormLabel>
              <div>
                {isComponent ? (
                  <div
                    style={{ display: "flex", justifyContent: "space-between", gap: '10px' }}
                  >
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title={
                        errors && errors.number ? errors.number.join("\n") : ""
                      }
                    >
                      <div style={{ width: "48%" }}>
                        <TextField
                          disabled={
                            isReadOnly ||
                            selectedAutocompleteComponent !==
                              emptyComponentOption
                          }
                          value={componentInfo.number}
                          onChange={(e) => {
                            if (errors.number) errors.number = null;
                            setComponentInfo({
                              ...componentInfo,
                              number: e.target.value.trimStart(),
                            });
                          }}
                          error={
                            errors && errors.number && errors.number.length > 0
                          }
                          variant="outlined"
                          margin="normal"
                          label={
                            translation.saveComponentModalComponentPartNumberLabel
                          }
                          fullWidth
                          id="assetsTblMenuDetailsModalID"
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title={
                        errors && errors.manufacturerPartNumber
                          ? errors.manufacturerPartNumber.join("\n")
                          : ""
                      }
                    >
                      <div style={{ width: "48%" }}>
                        <TextField
                          disabled={
                            isReadOnly ||
                            selectedAutocompleteComponent !==
                              emptyComponentOption
                          }
                          value={componentInfo.name}
                          onChange={(e) => {
                            if (errors.name) errors.name = null;
                            setComponentInfo({
                              ...componentInfo,
                              name: e.target.value,
                            });
                          }}
                          error={
                            errors && errors.name && errors.name.length > 0
                          }
                          variant="outlined"
                          margin="normal"
                          label={
                            translation.saveComponentModalComponentNameLabel
                          }
                          id="assetsTblMenuAddComponentModalDescription"
                          fullWidth
                        />
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title={
                        errors && errors.number ? errors.number.join("\n") : ""
                      }
                    >
                      <div style={{ width: "48%" }}>
                        <TextField
                          disabled={
                            isReadOnly ||
                            selectedAutocompleteComponent !==
                              emptyComponentOption
                          }
                          value={componentInfo.number}
                          onChange={(e) => {
                            if (errors.number) errors.number = null;
                            setComponentInfo({
                              ...componentInfo,
                              number: e.target.value.trimStart(),
                            });
                          }}
                          error={
                            errors && errors.number && errors.number.length > 0
                          }
                          variant="outlined"
                          margin="normal"
                          label={
                            translation.saveComponentModalComponentPartNumberLabel
                          }
                          fullWidth
                          id="assetsTblMenuDetailsModalID"
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title={
                        errors && errors.name ? errors.name.join("\n") : ""
                      }
                    >
                      <div style={{ width: "48%" }}>
                        <TextField
                          disabled={
                            isReadOnly ||
                            selectedAutocompleteComponent !==
                              emptyComponentOption
                          }
                          value={componentInfo.name}
                          onChange={(e) => {
                            if (errors.name) errors.name = null;
                            setComponentInfo({
                              ...componentInfo,
                              name: e.target.value.trimStart(),
                            });
                          }}
                          error={
                            errors && errors.name && errors.name.length > 0
                          }
                          variant="outlined"
                          margin="normal"
                          label={
                            translation.saveComponentModalComponentNameLabel
                          }
                          id="assetsTblMenuDetailsModalDescription"
                          fullWidth
                        />
                      </div>
                    </Tooltip>
                  </div>
                )}

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={
                      errors && errors.manufacturerName
                        ? errors.manufacturerName.join("\n")
                        : ""
                    }
                  >
                    <div style={{ width: "48%" }}>
                      <TextField
                        disabled={
                          isReadOnly ||
                          selectedAutocompleteComponent !== emptyComponentOption
                        }
                        value={componentInfo.manufacturerName}
                        onChange={(e) => {
                          if (errors.manufacturerName)
                            errors.manufacturerName = null;
                          setComponentInfo({
                            ...componentInfo,
                            manufacturerName: e.target.value.trimStart(),
                          });
                        }}
                        error={
                          errors &&
                          errors.manufacturerName &&
                          errors.manufacturerName.length > 0
                        }
                        variant="outlined"
                        margin="normal"
                        label={
                          translation.saveComponentModalManufacturerNameLabel
                        }
                        id="assetsTblMenuAddComponentModalManufacturerName"
                        fullWidth
                      />
                    </div>
                  </Tooltip>
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title={errors && errors.uId ? errors.uId.join("\n") : ""}
                  >
                    <div style={{ width: "48%" }}>
                      <TextField
                        disabled={
                          isReadOnly ||
                          selectedAutocompleteComponent !== emptyComponentOption
                        }
                        value={componentInfo.uId}
                        onChange={(e) => {
                          if (errors.uId) errors.uId = null;
                          setComponentInfo({
                            ...componentInfo,
                            uId: e.target.value.trimStart(),
                          });
                        }}
                        error={errors && errors.uId && errors.uId.length > 0}
                        variant="outlined"
                        margin="normal"
                        label={translation.saveComponentModalComponentUidLabel}
                        id="assetsTblMenuAddComponentModalSerialNumber"
                        fullWidth
                      />
                    </div>
                  </Tooltip>
                </div>
                {isComponent && (
                  <div>
                  <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title={
                          errors && errors.manufacturerPartNumber
                            ? errors.manufacturerPartNumber.join("\n")
                            : ""
                        }
                      >
                        <div style={{ width: "48%" }}>
                          <TextField
                            disabled={
                              isReadOnly ||
                              selectedAutocompleteComponent !==
                                emptyComponentOption
                            }
                            value={componentInfo.manufacturerPartNumber}
                            onChange={(e) => {
                              if (errors.manufacturerPartNumber)
                                errors.manufacturerPartNumber = null;
                              setComponentInfo({
                                ...componentInfo,
                                manufacturerPartNumber: e.target.value,
                              });
                            }}
                            error={
                              errors &&
                              errors.manufacturerPartNumber &&
                              errors.manufacturerPartNumber.length > 0
                            }
                            variant="outlined"
                            margin="normal"
                            label={
                              translation.saveComponentModalManufacturerModelLabel
                            }
                            id="assetsTblMenuAddComponentModalModel"
                            fullWidth
                          />
                        </div>
                      </Tooltip>
                  </div>
                )}
              </div>
            </div>

            <div className="formContentBlock modalFieldLabelSettings">
              <div>
                <FormLabel
                  component="legend"
                  disabled={isReadOnly}
                  className="mb5 modalFieldLabel"
                >
                  {translation.saveComponentModalWorkhoursLabel}
                </FormLabel>
                <div style={{ justifyContent: "space-between" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title={
                        errors && errors.workhoursUpdateType
                          ? errors.workhoursUpdateType.join("\n")
                          : ""
                      }
                    >
                      <div style={{ width: "48%" }}>
                        <TextField
                          disabled={
                            isReadOnly || compDetails || isUnassignedComponent
                          }
                          value={
                            componentInfo.workhoursUpdateType === "parent" &&
                            compDetails
                              ? ""
                              : componentInfo.workhoursUpdateType
                          }
                          onChange={(e) => {
                            if (errors.workhoursUpdateType)
                              errors.workhoursUpdateType = null;
                              // setComponentInfo(prevComponentInfo => {
                              //   let newWorkhoursTotal = 0;
                              
                              //   if (selectedAutocompleteComponent !== emptyComponentOption || e.target.value === "manual") {
                              //     newWorkhoursTotal =  prevComponentInfo.workhoursTotal;
                              //   } else if (prevComponentInfo.workhoursUpdateType === "parent") {
                              //     newWorkhoursTotal = prevComponentInfo.workhoursTotal !== undefined  ? prevComponentInfo.workhoursTotal : 0;
                              //   } 
                              
                              //   return {
                              //     ...prevComponentInfo,
                              //     workhoursUpdateType: e.target.value,
                              //     workhoursTotal: newWorkhoursTotal,
                              //     dmsUnitUId: "",
                              //     channelCode: "",
                              //     offset: 0,
                              //   };
                              // });
                            setComponentInfo({
                              ...componentInfo,
                              workhoursUpdateType: e.target.value,
                              workhoursTotal: 0,
                              dmsUnitUId: "",
                              channelCode: "",
                              offset: 0,
                            });
                          }}
                          error={
                            errors &&
                            errors.workhoursUpdateType &&
                            errors.workhoursUpdateType.length > 0
                          }
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label={
                            componentInfo.workhoursUpdateType === "parent" &&
                            compDetails
                              ? "Parent hour counter"
                              : ""
                          }
                          select
                          id="assetsTblMenuAddComponentModalChangeTypeWorkhours"
                        >
                          {updateTypesOptions &&
                            updateTypesOptions.map((updateTypesOption) => (
                              <MenuItem
                                key={updateTypesOption.updateType}
                                // disabled={updateTypesOption.updateType === 'sync'}
                                value={updateTypesOption.updateType}
                                className="filterMenuItem"
                                id="assetsTblMenuAddComponentModalChooseTypeWorkhours"
                              >
                                {updateTypesOption.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </div>
                    </Tooltip>
                    <div
                      className=" "
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "48%",
                        gap: "10px",
                      }}
                    >
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title={
                          errors && errors.workhoursTotal
                            ? errors.workhoursTotal.join("\n")
                            : ""
                        }
                      >
                        <div>
                          <TextField
                            disabled={
                              // ((selectedCatalogComponent.id !== emptyCatalogComponent.id) && !duplicateBasedOnComponent) ||
                              componentInfo.workhoursUpdateType === "parent" ||
                              componentInfo.workhoursUpdateType === "sync" ||
                              componentInfo.workhoursUpdateType ===
                                "dedicated" ||
                              componentInfo.workhoursUpdateType ===
                                "parallel" ||
                              // isUnassignedComponent ||
                              // editUnassignedComponents ||
                              isCatalog ||
                              isReadOnly
                            }
                            // type="number"
                            pattern="[0-9]*"
                            onKeyDown={(e) => {
                              if (
                                e.key === "+" ||
                                e.key === "-" ||
                                e.key === "."
                              ) {
                                e.preventDefault();
                              }
                            }}
                            value={componentInfo.workhoursTotal}
                            onChange={(e) => {
                              if (errors.workhoursTotal)
                                errors.workhoursTotal = null;
                              const numericValue = parseFloat(e.target.value);
                              setComponentInfo({
                                ...componentInfo,
                                workhoursTotal: isNaN(numericValue)
                                  ? ""
                                  : numericValue,
                              });
                            }}
                            error={
                              errors &&
                              errors.workhoursTotal &&
                              errors.workhoursTotal.length > 0
                            }
                            variant="outlined"
                            margin="normal"
                            // label={ componentInfo.workhoursUpdateType === 'parent' ? translation.saveComponentModalWorkhoursRelativeLabel : translation.saveComponentModalWorkhoursTotalLabel}
                            label={
                              translation.saveComponentModalWorkhoursTotalLabel
                            }
                            id="assetsTblMenuAddComponentModalWorkhoursTotal"
                          />
                        </div>
                      </Tooltip>
                      <Tooltip
                        disableFocusListener
                        disableTouchListener
                        title={
                          errors && errors.offset
                            ? errors.offset.join("\n")
                            : ""
                        }
                      >
                        <div>
                          <TextField
                            disabled={
                              componentInfo.workhoursUpdateType === "sync" ||
                              componentInfo.workhoursUpdateType ===
                                "dedicated" ||
                              componentInfo.workhoursUpdateType === "manual" ||
                              isCatalog ||
                              isReadOnly ||
                              isUnassignedComponent
                            }
                            type="number"
                            pattern="[0-9]*"
                            onKeyDown={(e) => {
                              if (e.key === "+" || e.key === ".") {
                                e.preventDefault();
                              }
                            }}
                            value={componentInfo?.offset}
                            onChange={(e) => {
                              if (errors.offset) errors.offset = null;
                              setComponentInfo({
                                ...componentInfo,
                                offset: e.target.value,
                              });
                            }}
                            error={
                              errors &&
                              errors.offset &&
                              errors.offset.length > 0
                            }
                            variant="outlined"
                            margin="normal"
                            label={
                              translation.saveComponentModalWorkhoursOffsetLabel
                            }
                            id="assetsTblMenuAddComponentModalOffset"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  {(componentInfo.workhoursUpdateType === "parallel" ||
                    componentInfo.workhoursUpdateType === "dedicated") && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          title={
                            errors && errors.dmsUnitUId
                              ? errors.dmsUnitUId.join("\n")
                              : ""
                          }
                        >
                          <div style={{ width: "48%" }}>
                            <TextField
                              disabled={isReadOnly}
                              value={
                                componentInfo.dmsUnitUId
                                  ? componentInfo.dmsUnitUId
                                  : "empty"
                              }
                              id="assetsTblMenuDetailsModalChangeUnitWorkhours"
                              onChange={(e) => {
                                if (errors.dmsUnitUId) errors.dmsUnitUId = null;
                                setComponentInfo({
                                  ...componentInfo,
                                  dmsUnitUId: e.target.value,
                                  channelCode: "",
                                });
                                setChannels(
                                  units.find((x) => x.uId === e.target.value)
                                    ? units.find(
                                        (x) => x.uId === e.target.value
                                      ).channels
                                    : ""
                                );
                              }}
                              error={
                                errors &&
                                errors.dmsUnitUId &&
                                errors.dmsUnitUId.length > 0
                              }
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              select
                            >
                              <MenuItem
                                key={emptyUnitOption.id}
                                className="filterMenuItem"
                                id="assetsTblMenuDetailsModalChooseUnitWorkhours"
                                value={
                                  componentInfo?.dmsUnitUId
                                    ? componentInfo?.dmsUnitUId
                                    : emptyUnitOption.id
                                }
                                onClick={() => {
                                  setComponentInfo({
                                    ...componentInfo,
                                    dmsUnitUId: emptyUnitOption.name,
                                    channelCode: "",
                                  });
                                  setChannels(
                                    units.find(
                                      (x) => x.uId === emptyUnitOption.id
                                    )
                                      ? units.find(
                                          (x) => x.uId === emptyUnitOption.id
                                        ).channels
                                      : ""
                                  );
                                }}
                              >
                                {componentInfo?.dmsUnitUId &&
                                units.find(
                                  (unit) =>
                                    unit.uId === componentInfo.dmsUnitUId
                                )
                                  ? emptyUnitOption.name
                                  : componentInfo?.dmsUnitUId
                                  ? componentInfo?.dmsUnitUId
                                  : emptyUnitOption.name}
                              </MenuItem>
                              {units &&
                                units.map((unit) => (
                                  <MenuItem
                                    key={unit.uId}
                                    value={unit.uId}
                                    className="filterMenuItem"
                                  >
                                    {`${unit.name}  (${unit.uId}) `}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </div>
                        </Tooltip>
                        <Tooltip
                          disableFocusListener
                          disableTouchListener
                          title={
                            errors && errors.channelCode
                              ? errors.channelCode.join("\n")
                              : ""
                          }
                        >
                          <div style={{ width: "48%" }}>
                            <TextField
                              disabled={isReadOnly || !componentInfo.dmsUnitUId}
                              value={
                                componentInfo.channelCode
                                  ? componentInfo.channelCode
                                  : "empty"
                              }
                              onChange={(e) => {
                                if (errors.channelCode)
                                  errors.channelCode = null;
                                setComponentInfo({
                                  ...componentInfo,
                                  channelCode: e.target.value,
                                  workhoursTotal: 0,
                                });
                              }}
                              error={
                                errors &&
                                errors.channelCode &&
                                errors.channelCode.length > 0
                              }
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              select
                              id="assetsTblMenuDetailsModalChangeChannelWorkhours"
                            >
                              <MenuItem
                                key={emptyChannelOption.id}
                                className="filterMenuItem"
                                value={
                                  componentInfo?.channelCode
                                    ? componentInfo?.channelCode
                                    : emptyChannelOption.id
                                }
                                onClick={() => {
                                  setComponentInfo({
                                    ...componentInfo,
                                    channelCode: emptyChannelOption.name,
                                    workhoursTotal: 0,
                                  });
                                }}
                              >
                                {componentInfo?.channelCode &&
                                channels &&
                                Array.isArray(channels) &&
                                channels.find(
                                  (unit) =>
                                    unit.name === componentInfo.channelCode
                                )
                                  ? emptyChannelOption.name
                                  : componentInfo?.channelCode
                                  ? componentInfo?.channelCode
                                  : emptyChannelOption.name}
                              </MenuItem>
                              {channels &&
                                Array.isArray(channels) &&
                                channels.map((channel) => (
                                  <MenuItem
                                    key={channel.name}
                                    value={channel.name}
                                    className="filterMenuItem"
                                    id="assetsTblMenuDetailsModalChooseChannelWorkhours"
                                  >
                                    {channel.nameLocalized}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </div>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {renderSyncWorkhoursCheckbox()}
            {renderDimBlock()}
            <div className="formContentBlock">
              {renderRecommendedPartsBlock()}
            </div>
            <div className="formContentBlock">{renderFileUploadBlock()}</div>
            {/* <div className="formContentBlock">
            <div className="mb5">
              <FormLabel component="legend" 
                               className="formNoteLabel">{translation.saveComponentModalNoteLabel}</FormLabel>
                  <TextField
                    value={componentInfo.note}
                    onChange={(e) => {
                      setComponentInfo({ ...componentInfo, note: e.target.value })
                    }}
                    type="text"
                    variant="outlined"
                    margin="none"
                    fullWidth
                    multiline={true}
                    rows={3}
                    className="formControlTextarea"
                  />
                </div>
            </div> */}
          </div>
          <div className="btnHolder end fixedWidthBtn">
            <Button
              disabled={!!disableButtons}
              variant="outlined"
              fullWidth
              id="assetsAddComponentModalCancelBtn"
              onClick={(e) => {
                handleClose();
                setErrors({});
                setSelectedAutocompleteComponent(emptyComponentOption);
                setSelectedOption('createNew');
                setIsVisibleUnassigned(false)
              }}
            >
              {translation.cancelButtonLabel}
            </Button>
            <Button
              disabled={!!disableButtons || isReadOnly}
              onClick={(e) => onSave()}
              variant="contained"
              color="primary"
              fullWidth
              id="partsAddComponentModalSaveBtn"
            >
              {component
                ? translation.saveButtonLabel
                : translation.addButtonLabel}
            </Button>
          </div>
          {partList && (
            <Popover
            anchorEl={partsMenuAnchorEl}
            open={Boolean(partsMenuAnchorEl)}
            onClose={() => {setPartsMenuAnchorElChange(null); handleSearch('')}}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{minWidth: "500px"}}
          >
            <div style={{padding: "4px 8px"}}>
              <TextField
                type="text"
                variant="outlined"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                className="partsSearchInput"
              />
            </div>
            {partList
              .filter((part) =>
                part.fullName.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((part) => (
                <MenuItem
                  key={part.id}
                  value={part}
                  onClick={(e) => onPartAdded(part)}
                  className="filterMenuItem"
                >
                  <Checkbox
                    checked={componentInfo.sparePartIds[part.id] !== undefined}
                    size="small"
                    id="assetsAddComponentChooseRecommendedPartsBtn"
                    color="default"
                  />
                  {part.fullName}
                </MenuItem>
              ))}
          </Popover>
          )}
        </div>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = ({ mms }) => ({
  currentDepartment: mms.currentDepartment,
  components: mms.components,
  componentsCatalog: mms.componentsCatalog,
  parts: mms.parts,
  partsCatalog: mms.partsCatalog,
});

const mapDispatchToProps = {
  setComponents,
  setComponentsCatalog,
  setParts,
  setPartsCatalog,
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveComponentModal);
