import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { MenuItem, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MmsService from 'Services/MMS/mms-api.service';
import { setParts, setPartsCatalog } from '../../../../Store/modules/mms/actions';
import { normalizeErrorResponse } from 'utils/form';
import { connect } from 'react-redux';
import AlertToastr from 'utils/alert';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import useTranslation from 'Hooks/useTranslation';
import { useAuth } from '../../../../Contexts/AuthProvider';

const AmIcon6 = () => <div className="amPlIcon" />;

const BlankPartData = {
    parentComponentId: '',
    name: '',
    number: '',
    uId: '',
    supplierName: '',
    supplierPartNumber: '',
    supplierDeliveryTime: '',
    manufacturerName: '',
    manufacturerPartNumber: '',
    manufacturerType: '',
    dimensionUnit: '',
    length: '',
    width: '',
    height: '',
    weightUnit: '',
    weight: '',
    fileIds: [],
    files: [],
    alternativePartId: '',
    
};

const SavePartModal = ({
                           open,
                           handleClose,
                           part,
                           currentDepartment,
                           parts,
                           setParts,
                           isCatalog,
                           partsCatalog,
                           setPartsCatalog,
                           isReadOnly,
                           fetchParts
                       }) => {
    const { currentCompany, currentUser } = useAuth();
    const [, setDimensionsOptions] = useState([]);
    const [, setWeightOptions] = useState([]);
    const [partInfo, setPartInfo] = useState(BlankPartData);
    const translation = useTranslation();
    const [disableButtons, setDisabledButtons] = useState(false);
    const emptyCatalogPart = useMemo(() => {
        return { id: 'empty', name: translation.saveActivityModalSelectAlternativePartLabel };
    }, [translation]);
    const [selectedCatalogPart, setSelectedCatalogPart] = useState(emptyCatalogPart);
    const inputFile = useRef(null);
    const [errors, setErrors] = useState({});
    const [additionalParts, setAdditionalParts] = useState([]);

    const availableParts = useMemo(() => {
        if (!parts) return [];
        if (!part) return parts;

        return parts.filter(p => p.id !== part.id);
    }, [parts, part]);

    useEffect(() => {
        const fetchDimensionsOptions = async () => {
            let response = await MmsService.getDimensionsOptions();
            setDimensionsOptions(response.data);
            if (response.data[0] && !part) {
                setPartInfo(partInfo => {
                    return { ...partInfo, dimensionUnit: response.data[0] };
                });
            }
        };
        const fetchWeightOptions = async () => {
            let response = await MmsService.getWeightOptions();
            setWeightOptions(response.data);
            if (response.data[0] && !part) {
                setPartInfo(partInfo => {
                    return { ...partInfo, weightUnit: response.data[0] };
                });
            }
        };
        const fetchPartsCatalog = async () => {
            try {
                const response = await MmsService.getPartsCatalog();
                setPartsCatalog(response.data);
            } catch (error) {
                if (error && error.response && error.response.data && error.response.data.message)
                    AlertToastr.showErrorAlert(error.response.data.message);
            }
        };
        if (!open)
            return;
        fetchDimensionsOptions();
        fetchWeightOptions();

        if (!isCatalog && !part)
            fetchPartsCatalog();
    }, [open, part, isCatalog, setPartsCatalog]);

    useEffect(() => {
        if (!open)
            return;
        setSelectedCatalogPart(emptyCatalogPart);
        if (part) {
            if (!part.files)
                part.files = [];
            if (!part.fileIds)
                part.fileIds = [];
            setPartInfo(part);
            if (part.options) {
                setAdditionalParts(part.options);
            }
        } else {
            setPartInfo({ ...BlankPartData, fileIds: [], files: [] });
            setAdditionalParts([]);
        }
    }, [part, open, emptyCatalogPart]);

    const onSave = () => {
        setDisabledButtons(true);
        if (part) {
            MmsService.updatePart(partInfo).then((response) => {
                if (response.data.isSuccessful) {
                    AlertToastr.showAlert(translation.notificationsPartUpdated);
                    if (isCatalog) {
                        const index = partsCatalog.findIndex(c => c.id === partInfo.id);
                        partsCatalog.splice(index, 1, partInfo);
                        setPartsCatalog([...partsCatalog]);
                    } else {
                        const index = parts.findIndex(c => c.id === partInfo.id);
                        parts.splice(index, 1, partInfo);
                        setParts([...parts]);
                    }
                    setDisabledButtons(false);
                }
                handleClose();
                fetchParts();
                setErrors({});
            })
                .catch((error) => {
                    if (!error || !error.response || !error.response.data)
                        return;
                    const resErrors = normalizeErrorResponse(error.response.data);
                    if (resErrors.errors)
                        setErrors(resErrors.errors);
                    setDisabledButtons(false);
                    if (resErrors && resErrors.message)
                        AlertToastr.showErrorAlert(resErrors.message);
                    if (error.response.data.errors)
                        error.response.data.errors.filter(x => x.PropertyName === '').map(x => {
                            AlertToastr.showErrorAlert(x.ErrorMessage);
                            return x;
                        });
                });
        } else {
            const companyId = isCatalog
                ? null
                : (currentCompany?.id || currentUser?.companyId || null);
            MmsService.createPart(partInfo, companyId).then((response) => {
                AlertToastr.showAlert(translation.notificationsPartAdded);
                if (response.data.isSuccessful) {

                    if (isCatalog) {
                        const newParts = [...partsCatalog, { ...partInfo, id: response.data.item.id }];
                        setPartsCatalog(newParts);
                    } else {
                        const newParts = [...parts, { ...partInfo, fullName: partInfo.name, id: response.data.item.id }];
                        setParts(newParts);
                    }
                    setDisabledButtons(false);
                }
                handleClose();
                fetchParts();
                setErrors({});
            })
                .catch((error) => {
                    if (!error || !error.response || !error.response.data)
                        return;
                    const resErrors = normalizeErrorResponse(error.response.data);
                    if (resErrors.errors)
                        setErrors(resErrors.errors);
                    setDisabledButtons(false);
                    if (resErrors && resErrors.message)
                        AlertToastr.showErrorAlert(resErrors.message);
                    if (error.response.data.errors)
                        error.response.data.errors.filter(x => x.PropertyName === '').map(x => {
                            AlertToastr.showErrorAlert(x.ErrorMessage);
                            return x;
                        });
                });
        }
    };

    const onFileSelected = (file) => {
        const departmentId = isCatalog ? null : currentDepartment.id;
        MmsService.uploadFileToDepartment(departmentId, file).then((response) => {
            if (response.data.isSuccessful) {
                setPartInfo({
                    ...partInfo,
                    fileIds: [...partInfo.fileIds, response.data.item.id],
                    files: [...partInfo.files, {...response.data.item}],
                });
            }
        }).catch(error => {
            if (error && error.response && error.response.data && error.response.data.message)
                AlertToastr.showErrorAlert(error.response.data.message);
        });
    };

    // const downloadFile = (fileId) => {
    //     MmsService.downloadFile(fileId).then(response => {
    //         const filename = partInfo.files.find(p => p.id === fileId).name;
    //         const element = document.createElement('a');
    //         const file = new Blob(['\ufeff' + response.data]);
    //         element.href = URL.createObjectURL(file);
    //         element.download = filename;
    //         element.click();
    //     }).catch(error => {
    //         if (error && error.response && error.response.data && error.response.data.message)
    //             AlertToastr.showErrorAlert(error.response.data.message);
    //     });
    // };

    const addPartBlock = () => {
        setAdditionalParts([...additionalParts, {
          id: '', 
          partId: partInfo.id, 
          number: '', 
          manufacturerName: '', 
          manufacturerPartNumber: ''
        }]);
    };
    

    const handleAdditionalPartChange = (index, field, value) => {
        const newAdditionalParts = [...additionalParts];
        newAdditionalParts[index][field] = value;
        setAdditionalParts(newAdditionalParts);
        setPartInfo({ ...partInfo, options: newAdditionalParts});
    };

    const removePartBlock = (index) => {
        const newAdditionalParts = additionalParts.filter((_, i) => i !== index);
        setAdditionalParts(newAdditionalParts);
        setPartInfo({ ...partInfo, options: newAdditionalParts });
    };


    const additionalPartBlocks = additionalParts.map((part, index) => (
        <div className="halfBlockContainer" key={index} style={{marginBottom: '10px'}}>
            <div style={{width: '100%', paddingLeft: '15px'}}>
                {/* {index === 0 && (
                    <FormLabel component="legend"
                               className="mb5 modalFieldLabel" style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                        {translation.savePartModalPartLabel}
                    </FormLabel>
                )} */}
                <Tooltip disableFocusListener disableTouchListener
                         title={errors && errors.options ? errors.options.join('\n') : ''}>
                    <div>
                        <TextField
                            disabled={isReadOnly}
                            value={part.number}
                            onChange={e => handleAdditionalPartChange(index, 'number', e.target.value.trimStart())}
                            error={
                                (errors && errors[""] && errors[""].length > 0) ||
                                (errors && errors[`options[${index}]`] && errors[`options[${index}]`].length > 0)
                            }
                            variant="outlined"
                            margin="normal"
                            label={translation.savePartModalPartNumberLabel}
                            id='partsAddPartModalOptionPartNumber'
                            fullWidth
                        />
                    </div>
                </Tooltip>
            </div>
            <div style={{width: '100%', paddingLeft: '15px'}}>
                {/* {index === 0 && (
                    <FormLabel component="legend"
                               className="mb5 modalFieldLabel">
                        {translation.savePartModalManufacturerLabel}
                    </FormLabel>
                )} */}
                <Tooltip disableFocusListener disableTouchListener
                         title={errors && errors.manufacturerName ? errors.manufacturerName.join('\n') : ''}>
                    <div>
                        <TextField
                            disabled={isReadOnly}
                            value={part.manufacturerName}
                            onChange={e => handleAdditionalPartChange(index, 'manufacturerName', e.target.value.trimStart())}
                            error={errors && errors.manufacturerName && errors.manufacturerName.length > 0}
                            variant="outlined"
                            margin="normal"
                            label="Manufacturer name"
                            fullWidth
                            id='partsAddPartModalOptionManufacturerName'
                        />
                    </div>
                </Tooltip>
            </div>
            <div style={{display: 'flex', alignItems: 'center', width: '100%',  paddingLeft: '15px', justifyContent: 'space-between'}}>
            <Tooltip disableFocusListener disableTouchListener
                         title={errors && errors.manufacturerPartNumber ? errors.manufacturerPartNumber.join('\n') : ''}>
                    <div style={{width: '100%'}}>
                        <TextField
                            disabled={isReadOnly}
                            value={part.manufacturerPartNumber}
                            onChange={e => handleAdditionalPartChange(index, 'manufacturerPartNumber', e.target.value.trimStart())}
                            error={errors && errors.manufacturerPartNumber && errors.manufacturerPartNumber.length > 0}
                            variant="outlined"
                            margin="normal"
                            label="Manufacturer Part Number"
                            fullWidth
                            id='partsAddPartModalOptionManufacturerPartNumber'
                        />
                    </div>
                </Tooltip>
                <div className="tfgItem btnItem" style={{marginBottom: '4px'}}>
                  <IconButton
                    className="iconBtnSm"
                    onClick={() => removePartBlock(index)}
                    id='partsAddPartModalDeleteOptionBtn'
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </div>
            </div>
        </div>
    ));

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={e => {
                    handleClose();
                    setErrors({});
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault()
                        onSave();
                    }
                }}
                className="respModal scrollContentModal autoHeight"
            >
                <div className="formContentHolder">
                    <div className="modalTitleHolder">
                        <h1 className="modalTitle text-center">{part ? translation.savePartModalEditPartLabel : translation.savePartModalAddPartLabel}</h1>
                    </div>
                    <div className="formContent">
                        <div className="formContentBlock modalFieldLabelSettings">
                            <div className="halfBlockContainer">
                                <div className="halfBlock" style={{width: '100%'}}>
                                    <FormLabel component="legend"
                                               className="mb5 modalFieldLabel"  style={{color: 'rgba(0, 0, 0, 0.87)'}} >{translation.savePartModalPartLabel}</FormLabel>
                                    <Tooltip disableFocusListener disableTouchListener
                                             title={errors && errors.name ? errors.name.join('\n') : ''}>
                                        <div>
                                            <TextField
                                                disabled={isReadOnly}
                                                value={partInfo.name}
                                                onChange={e => {
                                                    if (errors.name)
                                                        errors.name = null;
                                                    setPartInfo({ ...partInfo, name: e.target.value.trimStart() });
                                                }}
                                                error={errors && errors.name && errors.name.length > 0}
                                                variant="outlined"
                                                margin="normal"
                                                id="partsAddPartModalName"
                                                label={translation.savePartModalPartNameLabel}
                                                fullWidth
                                            />
                                        </div>
                                    </Tooltip>
                                    {/* <Tooltip disableFocusListener disableTouchListener
                                             title={errors && errors.number ? errors.number.join('\n') : ''}>
                                        <div>
                                            <TextField
                                                disabled={isReadOnly}
                                                value={partInfo.number}
                                                onChange={e => {
                                                    if (errors.number)
                                                        errors.number = null;
                                                    setPartInfo({ ...partInfo, number: e.target.value.trimStart() });
                                                }}
                                                error={errors && errors.number && errors.number.length > 0}
                                                variant="outlined"
                                                margin="normal"
                                                id="partsAddPartModalPartNumber"
                                                label={translation.savePartModalPartNumberLabel}
                                                fullWidth
                                            />
                                        </div>
                                    </Tooltip> */}
                                    {/* <Tooltip disableFocusListener disableTouchListener
                                             title={errors && errors.uId ? errors.uId.join('\n') : ''}>
                                        <div>
                                            <TextField
                                                disabled={isReadOnly}
                                                value={partInfo.uId}
                                                onChange={e => {
                                                    if (errors.uId)
                                                        errors.uId = null;
                                                    setPartInfo({ ...partInfo, uId: e.target.value });
                                                }}
                                                error={errors && errors.uId && errors.uId.length > 0}
                                                variant="outlined"
                                                margin="normal"
                                                label={translation.savePartModalPartUidLabel}
                                                fullWidth
                                            />
                                        </div>
                                    </Tooltip> */}
                                </div>
                                {/* <div className="halfBlock"> */}
                                {/* <FormLabel component="legend"
                                       className="mb5 modalFieldLabel">{translation.savePartModalManufacturerLabel}</FormLabel>
                            <Tooltip disableFocusListener disableTouchListener
                                     title={errors && errors.manufacturerName ? errors.manufacturerName.join('\n') : ''}>
                                <div>
                                    <TextField
                                        disabled={isReadOnly}
                                        value={partInfo.manufacturerName}
                                        onChange={e => {
                                            if (errors.manufacturerName)
                                                errors.manufacturerName = null;
                                            setPartInfo({ ...partInfo, manufacturerName: e.target.value.trimStart() });
                                        }}
                                        error={errors && errors.manufacturerName && errors.manufacturerName.length > 0}
                                        variant="outlined"
                                        margin="normal"
                                        id="partsAddPartModalManufacturerName"
                                        label={translation.savePartModalManufacturerNameLabel}
                                        fullWidth
                                    />
                                </div>
                            </Tooltip> */}
                            {/* <Tooltip disableFocusListener disableTouchListener
                                     title={errors && errors.manufacturerType ? errors.manufacturerType.join('\n') : ''}>
                                <div>
                                    <TextField
                                        disabled={isReadOnly}
                                        value={partInfo.manufacturerType}
                                        onChange={e => {
                                            if (errors.manufacturerType)
                                                errors.manufacturerType = null;
                                            setPartInfo({ ...partInfo, manufacturerType: e.target.value });
                                        }}
                                        error={errors && errors.manufacturerType && errors.manufacturerType.length > 0}
                                        variant="outlined"
                                        margin="normal"
                                        label={translation.savePartModalManufacturerTypeLabel}
                                        fullWidth
                                    />
                                </div>
                            </Tooltip> */}
                            {/* <Tooltip disableFocusListener disableTouchListener
                                     title={errors && errors.manufacturerPartNumber ? errors.manufacturerPartNumber.join('\n') : ''}>
                                <div>
                                    <TextField
                                        disabled={isReadOnly}
                                        value={partInfo.manufacturerPartNumber}
                                        onChange={e => {
                                            if (errors.manufacturerPartNumber)
                                                errors.manufacturerPartNumber = null;
                                            setPartInfo({ ...partInfo, manufacturerPartNumber: e.target.value.trimStart() });
                                        }}
                                        error={errors && errors.manufacturerPartNumber && errors.manufacturerPartNumber.length > 0}
                                        variant="outlined"
                                        margin="normal"
                                        id="partsAddPartModalManufacturerPartNum"
                                        label={translation.savePartModalManufacturerPartNumberLabel}
                                        fullWidth
                                    />
                                </div>
                            </Tooltip> */}
                                    {/* <FormLabel component="legend"
                                               className="mb5">{translation.savePartModalSupplierLabel}</FormLabel> */}
                                    {/* <Tooltip disableFocusListener disableTouchListener
                                             title={errors && errors.supplierName ? errors.supplierName.join('\n') : ''}>
                                        <div>
                                            <TextField
                                                disabled={isReadOnly}
                                                value={partInfo.supplierName}
                                                onChange={e => {
                                                    if (errors.supplierName)
                                                        errors.supplierName = null;
                                                    setPartInfo({ ...partInfo, supplierName: e.target.value });
                                                }}
                                                error={errors && errors.supplierName && errors.supplierName.length > 0}
                                                variant="outlined"
                                                margin="normal"
                                                label={translation.savePartModalSupplierNameLabel}
                                                fullWidth
                                            />
                                        </div>
                                    </Tooltip>
                                    <Tooltip disableFocusListener disableTouchListener
                                             title={errors && errors.supplierPartNumber ? errors.supplierPartNumber.join('\n') : ''}>
                                        <div>
                                            <TextField
                                                disabled={isReadOnly}
                                                value={partInfo.supplierPartNumber}
                                                onChange={e => {
                                                    if (errors.supplierPartNumber)
                                                        errors.supplierPartNumber = null;
                                                    setPartInfo({ ...partInfo, supplierPartNumber: e.target.value });
                                                }}
                                                error={errors && errors.supplierPartNumber && errors.supplierPartNumber.length > 0}
                                                variant="outlined"
                                                margin="normal"
                                                label={translation.savePartModalSupplierPartNumberLabel}
                                                fullWidth
                                            />
                                        </div>
                                    </Tooltip>
                                    <Tooltip disableFocusListener disableTouchListener
                                             title={errors && errors.supplierDeliveryTime ? errors.supplierDeliveryTime.join('\n') : ''}>
                                        <div>
                                            <TextField
                                                type="number"
                                                pattern="[0-9]*"
                                                onKeyDown={e => {
                                                    if (e.key === '+' || e.key === '-' || e.key === '.') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                disabled={isReadOnly}
                                                value={partInfo.supplierDeliveryTime}
                                                onChange={e => {
                                                    if (errors.supplierDeliveryTime)
                                                        errors.supplierDeliveryTime = null;
                                                    setPartInfo({ ...partInfo, supplierDeliveryTime: e.target.value });
                                                }}
                                                error={errors && errors.supplierDeliveryTime && errors.supplierDeliveryTime.length > 0}
                                                variant="outlined"
                                                margin="normal"
                                                label={translation.savePartModalSupplierDeliveryTimeLabel}
                                                fullWidth
                                            />
                                        </div>
                                    </Tooltip> */}
                                {/* </div> */}
                            </div>
                        </div>
                        {/* <div className="formContentBlock">
                            
                        </div> */}
                        <div className="formContentBlock modalFieldLabelSettings">
                            <div className="wrapper content-justified align-center mb5">
                                <FormLabel component="legend"
                                           className="modalFieldLabel">Part Details</FormLabel>
                                <Button
                                    disabled={isReadOnly}
                                    startIcon={<AmIcon6 />}
                                    onClick={addPartBlock}
                                    id='partsAddPartModalAddOptionBtn'
                                >
                                    Add Details
                                </Button>
                            </div>
                            {additionalPartBlocks}
                        </div>

                        {/* <div className="formContentBlock modalFieldLabelSettings">
                            <FormLabel component="legend"
                                       className="mb5 modalFieldLabel">{translation.saveActivityModalAlternativePartLabel}</FormLabel>
                            <Tooltip disableFocusListener disableTouchListener
                                     title={errors && errors.alternativePartId ? errors.alternativePartId.join('\n') : ''}>
                                <div>
                                    <TextField
                                        disabled={isReadOnly}
                                        value={partInfo.alternativePartId || selectedCatalogPart.id}
                                        onChange={e => {
                                            if (errors.alternativePartId)
                                                errors.alternativePartId = null;
                                            setPartInfo({ ...partInfo, alternativePartId: e.target.value });
                                        }}
                                        error={errors && errors.alternativePartId && errors.alternativePartId.length > 0}
                                        variant="outlined"
                                        margin="normal"
                                        select
                                        fullWidth
                                        id="partsAddPartModalAlternativePartChange"
                                    >
                                        <MenuItem key={emptyCatalogPart.id} className="filterMenuItemDisabled" value={emptyCatalogPart.id}>
                                            {emptyCatalogPart.name}
                                        </MenuItem>
                                        {availableParts && availableParts.map((part) => {
                                            return <MenuItem id="partsAddPartModalAlternativePartChoose" key={part.id} value={part.id}
                                                             className="filterMenuItem">{part.fullName}</MenuItem>;
                                        })}
                                    </TextField>
                                </div>
                            </Tooltip>
                        </div> */}
                        <div className="formContentBlock modalFieldLabelSettings">
                            <div className="wrapper content-justified align-center mb5">
                                <FormLabel component="legend"
                                           className="modalFieldLabel">{`${translation.savePartModalUserManualsLabel} (${partInfo.fileIds.length})`}</FormLabel>
                                <Button
                                    disabled={isReadOnly}
                                    onClick={e => inputFile.current.click()}
                                    startIcon={<AmIcon6 />}
                                    id="partsAddPartModalUploadFile"
                                >
                                    {translation.savePartModalUploadButtonLabel}
                                </Button>
                                <input type="file" id="file"
                                       ref={inputFile} style={{ display: 'none' }}
                                       onChange={(e) => {
                                           if (!e.target.files[0])
                                               return;
                                           onFileSelected(e.target.files[0]);
                                       }} />
                            </div>
                            <ul className="partsListGroup">
                                {partInfo.fileIds && partInfo.fileIds.map(fileId => {
                                    const file = partInfo.files.find(p => p.id === fileId);
                                    return (
                                        <li key={fileId}>
                                    <div className="name">{partInfo.files.find(p => p.id === fileId).name}</div>
                                    <div className="btnPart">
                                        <IconButton className="delete"
                                                    size="small"
                                                    style={{ color: '#b1b3b5' }}
                                                    onClick={(e) => {
                                                        fetch(file.url)
                                                          .then(response => response.blob())
                                                          .then(blob => {
                                                            const link = document.createElement("a");
                                                            link.href = URL.createObjectURL(blob);
                                                            link.download = file.name;
                                                            link.click();
                                                          });
                                                      }}
                                                      id="partsAddPartModalDownloadFile"
                                        ><GetAppIcon /></IconButton>
                                        <IconButton className="delete"
                                                    size="small"
                                                    disabled={isReadOnly}
                                                    style={{ color: '#b1b3b5' }}
                                                    onClick={(e) => {
                                                        if (selectedCatalogPart.id === emptyCatalogPart.id)
                                                            setPartInfo({
                                                                ...partInfo,
                                                                fileIds: partInfo.fileIds.filter(p => p !== fileId),
                                                            });
                                                    }}
                                                    id="partsAddPartModalDeleteFile"
                                        ><HighlightOffIcon /></IconButton>
                                    </div>
                                </li>
                                    )
                                }
                                    
                                )}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="btnHolder end fixedWidthBtn">
                        <Button
                            disabled={!!disableButtons}
                            variant="outlined"
                            fullWidth
                            onClick={e => {
                                handleClose();
                                setErrors({});
                            }}
                            id="partsAddPartModalCancelBtn"
                        >
                            {translation.cancelButtonLabel}
                        </Button>
                        <Button
                            disabled={!!disableButtons || isReadOnly}
                            onClick={(e) => onSave()}
                            variant="contained"
                            color="primary"
                            fullWidth
                            id="partsAddPartModalSaveBtn"
                        >
                            {part ? translation.saveButtonLabel : translation.addButtonLabel}
                        </Button>
                    {/* <Menu
                        anchorEl={filesMenuAnchorEl}
                        open={Boolean(filesMenuAnchorEl)}
                        onClose={() => setFilesMenuAnchorElChange(null)}
                    >
                        <MenuItem key={uploadFileOption.id} value={uploadFileOption}
                            onClick={(e) => { setFilesMenuAnchorElChange(null); inputFile.current.click() }}>
                            {uploadFileOption.name}
                        </MenuItem>
                        {fileList && partInfo.fileIds && fileList.map((file) => (
                            <MenuItem key={file.id} value={file} onClick={(e) => onFileAdded(file)}>
                                <Checkbox
                                    checked={partInfo.fileIds.includes(file.id)}
                                    color='default' />
                                {file.name}
                            </MenuItem>
                        ))
                        }
                    </Menu> */}
                </div>
            </Dialog>
        </Fragment>
    );
};

const mapStateToProps = ({ mms }) => ({
    currentDepartment: mms.currentDepartment,
    parts: mms.parts,
    partsCatalog: mms.partsCatalog,
});

const mapDispatchToProps = {
    setParts,
    setPartsCatalog,
};

export default connect(mapStateToProps, mapDispatchToProps)(SavePartModal);
