import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { TextField, Tooltip } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { MenuItem, Checkbox } from '@material-ui/core'
import FormLabel from '@material-ui/core/FormLabel'
import GetAppIcon from '@material-ui/icons/GetApp'
import MmsService from 'Services/MMS/mms-api.service'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { normalizeErrorResponse } from 'utils/form'
import { connect } from 'react-redux'
import AlertToastr from 'utils/alert'
import IconButton from '@material-ui/core/IconButton'
import moment from 'moment'
import Popover from '@material-ui/core/Popover'
import useTranslation from 'Hooks/useTranslation'
import enGB from 'date-fns/locale/en-GB'
import ru from 'date-fns/locale/ru'
import { useAppSettings } from 'Contexts/AppSettingsProvider'
import Autocomplete from '@material-ui/lab/Autocomplete';

const AmIcon6 = () => {
  return (
    <div className="amPlIcon"/>
  )
}

const BlankActivityData = {
  id: '',
  employeeId: 'empty',
  date: null,

  timeAmount: '',
  completeWorkhours: '',
  timeAmountDateRange: '',
  tasks: [],
  comments: '',
  fileIds: [],
  files: [],
}

const InfoHolder = ({ task, partData, parts, alternativePart }) => {
  const translation = useTranslation()
  const [anchorElPopover, setAnchorElPopover] = React.useState(null)

  const handlePopoverClick = (event) => {
    setAnchorElPopover(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorElPopover(null)
  }

  const openPopover = Boolean(anchorElPopover)

  return (
    <div className="infoHolder">
      <div className="icoHolder" onClick={handlePopoverClick}/>
      <div className="popoverHolder">

        <Popover
          open={openPopover}
          anchorEl={anchorElPopover}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          className="activitiesTaskPopover pmPopover equipmentSubMenu"
          elevation={1}
        >
          <div className="title mb5">Task instruction</div>
          <div className="">
            Part to replace:&nbsp;
            {partData ? parts.find(x => x.id === task.partId).fullName : ''}
          </div>
          <div className="">QTY:&nbsp;{task.quantity}</div>
          {/* <div className="">
            {translation.markAsCompletedModalTaskInstructionPopoverAlternativePart}&nbsp;
            {alternativePart}
          </div> */}

        </Popover>
      </div>
    </div>
  )
}

const CompleteActivityModal = ({
                                 isOpen,
                                 isOpenManager,
                                 activity,
                                 employees,
                                 parts,
                                 currentDepartment,
                                 handleCompleteUpdate,
                                 employeesOptions
                               }) => {
  const translation = useTranslation()
  const { appLanguage } = useAppSettings()
  const emptyPartOption = useMemo(() => {
    return { id: 'empty', fullName: translation.markAsCompletedModalSelectPartLabel }
  }, [translation])
  const emptyAssagneeOption = useMemo(() => {
    return { id: 'empty', name: translation.markAsCompletedModalSelectAssigneeLabel }
  }, [translation])
  const [activityInfo, setActivityInfo] = useState(BlankActivityData)
  const [workTimeTypeOptions, setWorkTimeTypeOptions] = useState([])
  const [disableButtons, setDisabledButtons] = useState(false)
  const inputFile = useRef(null)
  const [errors, setErrors] = useState({})
  const [isMobile, setIsMobile] = useState(false)
  const partOptions = useMemo(() => {
    return [...(parts || [])];
  }, [parts]);

  useEffect(() => {
    const fetchWorkTimeTypeOptions = async () => {
      let response = await MmsService.getWorkTimeTypeOptions()
      setWorkTimeTypeOptions(response.data)
      if (response.data[0]) {
        setActivityInfo(activityInfo => {
          return {
            ...activityInfo,
            timeAmountDateRange: response.data[0].dateRange,
            tasks: activityInfo.tasks.map(task => ({ ...task, isCompleted: true })),
          }
        })
      }
    }
    if (!isOpen)
      return
    fetchWorkTimeTypeOptions()
  }, [isOpen])

  useEffect(() => {
    if (!isOpen || !activity)
      return
    setActivityInfo({
      ...activity,
      employeeId: activity.assigneeId ? activity.assigneeId : 'empty',
      date: moment().format().split('+')[0],
    })
  }, [activity, isOpen])

  const handleClose = () => {
    isOpenManager(false)
    setErrors({})
  }

  useEffect(() => {
    registerLocale('English', enGB)
    registerLocale('Russian', ru)

    function handleResize () {
      setIsMobile(window.innerWidth <= 576)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const onFileSelected = (file) => {
    MmsService.uploadFileToDepartment(currentDepartment.id, file).then((response) => {
      if (response.data.isSuccessful) {
        setActivityInfo({
          ...activityInfo,
          fileIds: [...activityInfo.fileIds, response.data.item.id],
          files: [...activityInfo.files, {...response.data.item}],
        })
      }
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message)
        AlertToastr.showErrorAlert(error.response.data.message)
    })
  }

  const downloadFile = (fileId) => {
    MmsService.downloadFile(fileId).then(response => {
      const filename = activityInfo.files.find(p => p.id === fileId).name
      const element = document.createElement('a')
      const file = new Blob(['\ufeff' + response.data])
      element.href = URL.createObjectURL(file)
      element.download = filename
      element.click()
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message)
        AlertToastr.showErrorAlert(error.response.data.message)
    })
  }

  const onComplete = () => {
    setDisabledButtons(true)
    const formatedActivityInfo = { ...activityInfo }
    formatedActivityInfo.tasks = activityInfo.tasks.map(x => {
      if (x.partReplacedId === emptyPartOption.id)
        x.partReplacedId = null
      if (x.partReplacedQuantity === '')
        x.partReplacedQuantity = null
      else
        x.partReplacedQuantity = +x.partReplacedQuantity
      return x
    })
    if (formatedActivityInfo.timeAmount === '') {
      formatedActivityInfo.timeAmount = null
      formatedActivityInfo.timeAmountDateRange = null
    }
    if (formatedActivityInfo.employeeId === 'empty') {
      formatedActivityInfo.employeeId = null
    }
    MmsService.completeActivity(formatedActivityInfo).then((response) => {
      if (response.data.isSuccessful) {
        setDisabledButtons(false)
        handleClose()
        handleCompleteUpdate(activity)
        AlertToastr.showAlert(translation.notificationsActivityCompleted)
      }
    })
      .catch((error) => {
        if (!error || !error.response || !error.response.data)
          return
        const resErrors = normalizeErrorResponse(error.response.data)
        if (resErrors.errors)
          setErrors(resErrors.errors)
        setDisabledButtons(false)
        if (resErrors && resErrors.message)
          AlertToastr.showErrorAlert(resErrors.message)
        if (error.response.data.errors)
          error.response.data.errors.filter(x => x.PropertyName === '').map(x => {
            AlertToastr.showErrorAlert(x.ErrorMessage)
            return x
          })
      })
  }

  return (
    <Fragment>
      <Dialog open={isOpen}
              onClose={e => isOpenManager(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && activityInfo.comments === '') {
                  e.preventDefault();
                  onComplete();
                }
            }}
              className="respModal scrollContentModal autoHeight"
      >
        <div className="formContentHolder">
          <div className="modalTitleHolder">
            <h1 className="modalTitle text-center">{translation.markAsCompletedModalLabel}</h1>
          </div>
          <div className="formContent">
            <div className="formContentBlock modalFieldLabelSettings">
              <TextField
                value={activityInfo.employeeId ? activityInfo.employeeId : 'empty'}
                onChange={e => {
                  setActivityInfo({ ...activityInfo, employeeId: e.target.value })
                }}
                variant="outlined"
                margin="normal"
                label={translation.markAsCompletedModalEmployeeLabel}
                fullWidth
                select
              >
                <MenuItem
                  key={emptyAssagneeOption.id}
                  id="activitiesTblMenuMASModalChangeAssignee"
                  className="filterMenuItem"
                  value={emptyAssagneeOption.id}
                >
                  {emptyAssagneeOption.name}
                </MenuItem>
                {currentDepartment
                  ? <p className="filterMenuItemDisabled">{translation.saveDepartmentModalFromDepartmentLabel}</p>
                  : <p style={{ display: 'none' }}></p>}

                {employeesOptions.fromDepartment.map((employee) => (
                  <MenuItem
                    key={employee.id}
                    id="activitiesTblMenuMASModalChooseFromDepAssignee"
                    className="filterMenuItem"
                    value={employee.id}
                  >
                    {employee.name}
                  </MenuItem>
                ))}

                {currentDepartment
                  ? <p className="filterMenuItemDisabled">{translation.saveDepartmentModalOthersLabel}</p>
                  : <p style={{ display: 'none' }}></p>}

                {employeesOptions.other.map((employee) => (
                  <MenuItem
                    key={employee.id}
                    id="activitiesTblMenuMASModalChooseOthersAssignee"
                    className="filterMenuItem"
                    value={employee.id}
                  >
                    {employee.name}
                  </MenuItem>
                ))}
              </TextField>
              <div className="textFieldGroup markAsCompletedGroup">
                <div className="tfgItem">
                  <div
                    className={errors && errors.date ? 'datePickerWithLabel hasError' : 'datePickerWithLabel'}>
                    <Tooltip disableFocusListener disableTouchListener
                             title={errors && errors.date ? errors.date.join('\n') : ''}>
                      <div>
                        <div className="labelLike"
                             style={{ left: '-10px' }}>{translation.markAsCompletedModalDateLabel}</div>
                        <DatePicker
                          onChange={e => {
                            if (errors.date)
                              errors.date = null
                            setActivityInfo({
                              ...activityInfo,
                              date: moment(e).format().split('+')[0],
                            })
                          }}
                          id="activitiesTblMenuMASModalDate"

                          maxDate={Date.now()}
                          value={activityInfo.date ? moment(activityInfo.date).format('L') : ''}
                          selected={activityInfo.date ? new Date(activityInfo.date) : new Date()}
                          className="formControlDatePicker"
                          withPortal={isMobile}
                          locale={appLanguage}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="tfgItem">
                  <Tooltip disableFocusListener disableTouchListener
                           title={errors && errors.completeWorkhours ? errors.completeWorkhours.join('\n') : ''}>
                    <div>
                      <TextField
                        type="number"
                        value={activityInfo.completeWorkhours}
                        onChange={e => {
                          if (errors.completeWorkhours)
                            errors.completeWorkhours = null
                          setActivityInfo({ ...activityInfo, completeWorkhours: e.target.value })
                        }}
                        error={errors && errors.completeWorkhours && errors.completeWorkhours.length > 0}
                        variant="outlined"
                        margin="normal"
                        id="activitiesTblMenuMASModalWorkhours"
                        label={translation.markAsCompletedModalAmountLabel}
                        fullWidth
                      />
                    </div>
                  </Tooltip>
                </div>
                <div className="tfgItem">
                  {/*Todo*/}
                  <div className="doubleInput hasSelect" style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip disableFocusListener disableTouchListener
                             title={errors && errors.timeAmount ? errors.timeAmount.join('\n') : ''}>
                      <TextField
                        type="number"
                        value={activityInfo.timeAmount}
                        onChange={e => {
                          if (errors.timeAmount)
                            errors.timeAmount = null
                          setActivityInfo({ ...activityInfo, timeAmount: e.target.value })
                        }}
                        error={errors && errors.timeAmount && errors.timeAmount.length > 0}
                        variant="outlined"
                        margin="normal"
                        id="activitiesTblMenuMASModalTimeInterval"
                        // label={translation.markAsCompletedModalAmountLabel}
                        label={translation.markAsCompletedModalIntervalLabel}                                                // fullWidth
                      />
                    </Tooltip>
                    <Tooltip disableFocusListener disableTouchListener
                             title={errors && errors.timeAmountDateRange ? errors.timeAmountDateRange.join('\n') : ''}>
                      <TextField
                        value={activityInfo.timeAmountDateRange}
                        onChange={e => {
                          if (errors.timeAmountDateRange)
                            errors.timeAmountDateRange = null
                          setActivityInfo({
                            ...activityInfo,
                            timeAmountDateRange: e.target.value,
                          })
                        }}
                        error={errors && errors.timeAmountDateRange && errors.timeAmountDateRange.length > 0}
                        variant="outlined"
                        margin="normal"
                        // label={translation.markAsCompletedModalIntervalLabel}
                        // fullWidth
                        select
                        className="last"
                        id="activitiesTblMenuMASModalChangeTimeIntervalType"
                      >
                        {workTimeTypeOptions && workTimeTypeOptions.map((workTimeTypeOption) =>
                          <MenuItem key={workTimeTypeOption.dateRange}
                                    id="activitiesTblMenuMASModalChooseTimeIntervalType"
                                    className="filterMenuItem"
                                    value={workTimeTypeOption.dateRange}>
                            {workTimeTypeOption.name}
                          </MenuItem>)}
                      </TextField>
                    </Tooltip>
                  </div>


                </div>
              </div>

              {/*<div className="halfBlockContainer">*/}
              {/*    <div className="halfBlock">*/}
              {/*<FormLabel component="legend" style={{lineHeight: '36px' }} className=''>Performed by</FormLabel>*/}

              {/*</div>*/}
              {/*<div className="halfBlock">*/}
              {/*<FormLabel component="legend" style={{ lineHeight: '36px' }} className=''>Date</FormLabel>*/}

              {/* <TextField
                                        value={activityInfo.workhours}
                                        onChange={e => {
                                            if (errors.workhours)
                                                errors.workhours = null
                                            setActivityInfo({ ...activityInfo, workhours: e.target.value })
                                        }}
                                        error={errors && errors.workhours && errors.workhours.length > 0}
                                        helperText={errors && errors.workhours ? errors.workhours.join('\n') : ''}
                                        variant="outlined"
                                        margin='normal'
                                        label='Workhours'
                                        fullWidth
                                    >
                                    </TextField>*/}

              {/*</div>*/}
              {/*</div>*/}
            </div>
            <div className="formContentBlock modalFieldLabelSettings">
              <div className="activitiesTaskTableHolder">
                <table className="activitiesTaskTable">
                  <thead>
                  <tr>
                    <th>{translation.markAsCompletedModalTaskHeader}</th>
                    <th/>
                    <th/>
                    <th className="checkBoxCell" style={{
                      padding: '0',
                      textAlign: 'right',
                    }}>{translation.markAsCompletedModalDoneHeader}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {activityInfo && activityInfo.tasks.map(task => {

                    const partData = parts && parts.find(x => x.id === task.partId)
                    let alternativePart = '-'
                    if (partData && partData.alternativePartId) {
                      const alternativePartModel = parts.find(x => x.id === partData.alternativePartId)
                      alternativePart = alternativePartModel.fullName
                    }

                    return (
                      <tr key={task.id}>
                        <td style={{ width: '33%' }}>
                          <div
                            className={`taskNameHolder ${task.isCompleted ? 'completed' : ''}`}>
                            <div className="name">{task.taskName}</div>
                            <InfoHolder
                              task={task}
                              partData={partData}
                              alternativePart={alternativePart}
                              parts={parts}
                            />
                          </div>
                        </td>
                        <td style={{ width: '34.4%' }}>
                          <div className="attInputHolder">
                          <Autocomplete
                            options={partOptions}
                            getOptionLabel={(option) => option.fullName}
                            value={partOptions.find(option => option.id === task.partReplacedId)}
                            onChange={(event, newValue) => {
                              task.partReplacedId = newValue.id;
                              setActivityInfo({
                                ...activityInfo,
                                tasks: [...activityInfo.tasks],
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                margin="none"
                                label={translation.markAsCompletedModalPartReplacedHeader}
                                size="small"
                                fullWidth
                              />
                            )}
                            size="small"
                            disableClearable
                          />
                          </div>
                        </td>
                        <td className="qntCell">
                          <div className="qntHolder">
                            <div className="attInputHolder qntInput">
                              <TextField
                                type="number"
                                variant="outlined"
                                margin="none"
                                fullWidth
                                size="small"
                                value={task.partReplacedQuantity}
                                onChange={(e) => {
                                  task.partReplacedQuantity = e.target.value
                                  setActivityInfo({
                                    ...activityInfo, tasks: [...activityInfo.tasks],
                                  })
                                }}
                                label={translation.markAsCompletedModalQtyHeader}
                              />
                            </div>
                          </div>
                        </td>
                        <td className="checkBoxCell" style={{ padding: '0' }}>
                          <div className="checkBoxLikeHolder" style={{ marginLeft: 'auto' }}>
                            <Checkbox
                              color="default"
                              size="small"
                              checked={Boolean(task.isCompleted)}
                              id="activitiesTblMenuMASModalTaskCheckbox"
                              onChange={(e) => {
                                e.persist()
                                task.isCompleted = e.target.checked
                                setActivityInfo({
                                  ...activityInfo, tasks: [...activityInfo.tasks],
                                })
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                  {activityInfo && (!activityInfo.tasks || activityInfo.tasks.length === 0) &&
                    <tr>
                      <td colSpan="4"
                          className="emptyCellHolder">{translation.markAsCompletedModalNoTasksWereAdded}</td>
                    </tr>}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="formContentBlock modalFieldLabelSettings">
              <div className="halfBlockContainer">
                <div className="halfBlock">
                  <div className="wrapper content-justified align-center mb5">
                    <FormLabel component="legend" style={{ lineHeight: '38px' }}
                               className="modalFieldLabel">{translation.markAsCompletedModalCommentsLabel}</FormLabel>
                    {/* <Button
                                            onClick={e => {
                                                commentList.push({ value: '', isEditing: true })
                                                setCommentList([...commentList])
                                            }}
                                            startIcon={<AmIcon6 />}
                                        >
                                            Add
                                        </Button> */}
                  </div>
                  {/*<ClickAwayListener onClickAway={(e) => {*/}
                  {/*    if (!isEditingComments)*/}
                  {/*        return*/}
                  {/*    setIsEditingComments(false)*/}
                  {/*}}>*/}
                  {/*    <ul className="partsListGroup"*/}
                  {/*        onClick={e => {*/}
                  {/*            setIsEditingComments(true)*/}
                  {/*        }}>*/}
                  {/*        <li>*/}
                  {/*            <div className="editTextOnClick"*/}
                  {/*                onClick={e => {*/}
                  {/*                    setIsEditingComments(true)*/}
                  {/*                }}>*/}
                  {/*                <div className="captionPart">*/}
                  {/*                    {*/}
                  {/*                        isEditingComments ?*/}
                  {/*                            <TextField*/}
                  {/*                                margin='none'*/}
                  {/*                                fullWidth*/}
                  {/*                                value={activityInfo.comments}*/}
                  {/*                                autoFocus={true}*/}
                  {/*                                multiline*/}
                  {/*                                onChange={(e) => {*/}
                  {/*                                    setActivityInfo({ ...activityInfo, comments: e.target.value })*/}
                  {/*                                }}*/}
                  {/*                            /> :*/}
                  {/*                            <div className="name">{activityInfo.comments}</div>*/}
                  {/*                    }*/}
                  {/*                    {activityInfo.comments && <div className="btnPart">*/}
                  {/*                        <IconButton className="delete"*/}
                  {/*                            size="small"*/}
                  {/*                            style={{ color: '#b1b3b5' }}*/}
                  {/*                            onClick={(e) => {*/}
                  {/*                                setActivityInfo({ ...activityInfo, comments: '' })*/}
                  {/*                            }}*/}
                  {/*                        ><HighlightOffIcon /></IconButton>*/}
                  {/*                    </div>}*/}
                  {/*                </div>*/}
                  {/*            </div>*/}
                  {/*        </li>*/}
                  {/*    </ul>*/}
                  {/*</ClickAwayListener>*/}
                  <TextField
                    value={activityInfo.comments}
                    onChange={(e) => {
                      setActivityInfo({ ...activityInfo, comments: e.target.value })
                    }}
                    type="text"
                    variant="outlined"
                    margin="none"
                    fullWidth
                    multiline={true}
                    rows={3}
                    className="formControlTextarea"
                    id="activitiesTblMenuMASModalCommentField"
                  />
                </div>
                <div className="halfBlock">
                  <div className="wrapper content-justified align-center mb5">
                    <FormLabel component="legend" style={{ lineHeight: '38px' }}
                               className="modalFieldLabel">{translation.markAsCompletedModalFilesLabel}</FormLabel>
                    <Button
                      onClick={e => inputFile.current.click()}
                      id="activitiesTblMenuMASModalUploadFileBtn"
                      startIcon={<AmIcon6/>}
                    >
                      {translation.markAsCompletedModalUploadButtonLabel}
                    </Button>
                    <input type="file" id="file"
                           ref={inputFile} style={{ display: 'none' }}
                           onChange={(e) => {
                             if (!e.target.files[0])
                               return
                             onFileSelected(e.target.files[0])
                           }}/>
                  </div>
                  <ul className="partsListGroup">
                  {activityInfo.fileIds && activityInfo.fileIds.map(fileId => {
                                    const file = activityInfo.files.find(p => p.id === fileId);
                                    return (
                                        <li key={fileId}>
                                    <div className="name">
                                    {file.name}
                                    </div>
                      <div className="btnPart">
                        <IconButton className="download"
                                    size="small"
                                    style={{ color: '#b1b3b5' }}
                                    id="activitiesTblMenuMASModalDownloadFileBtn"
                                    onClick={(e) => downloadFile(fileId)}
                        ><GetAppIcon/></IconButton>
                        <IconButton className="delete"
                                    size="small"
                                    style={{ color: '#b1b3b5' }}
                                    id="activitiesTblMenuMASModalDeleteFileBtn"
                                    onClick={(e) => {
                                      setActivityInfo({
                                        ...activityInfo,
                                        fileIds: activityInfo.fileIds.filter(p => p !== fileId),
                                      })
                                    }}
                        ><HighlightOffIcon/></IconButton>
                      </div>
                    </li>
                      )
                    }
                      
                    )}
                  </ul>
                </div>
              </div>

            </div>
          </div>
          <div className="btnHolder end fixedWidthBtn">
            <div className="part">
              <Button
                disabled={!!disableButtons}
                variant="outlined"
                fullWidth
                onClick={(e) => isOpenManager(false)}
                id="activitiesTblMenuMASModalCancelBtn"
              >
                {translation.cancelButtonLabel}
              </Button>
            </div>
            <div className="part">
              <Button
                disabled={!!disableButtons}
                variant="contained"
                color="primary"
                fullWidth
                onClick={onComplete}
                id="activitiesTblMenuMASModalSaveBtn"
              >
                {translation.completeButtonLabel}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  )
}

const mapStateToProps = ({ mms }) => ({
  currentDepartment: mms.currentDepartment,
  parts: mms.parts,
  typeOptions: mms.typeOptions,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteActivityModal)
