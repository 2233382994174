import React, { useState, useEffect, useMemo, Fragment } from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog"
import DmsService from 'Services/DMS/dms-api.service'
import { normalizeErrorResponse } from 'utils/form'
import AlertToastr from 'utils/alert'
import { connect } from 'react-redux'
import { setEditingCompany } from 'Store/modules/mms/actions'
import { setCompanies } from 'Store/modules/dashboard/actions'
import useTranslation from 'Hooks/useTranslation'
import useTimezones from '../../../../Hooks/mms/useTimezones';
import { MenuItem } from '@material-ui/core';

const SaveCompanyModal = ({ open, handleClose, editingCompany, setEditingCompany, companies, setCompanies }) => {
    const translation = useTranslation();
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [timezone, setTimezone] = useState('')
    const [disableButtons, setDisabledButtons] = useState(false)
    const [errors, setErrors] = useState({})

    const timezonesRequest = useTimezones();

    const timezoneOptions = useMemo(() => {
        if (timezonesRequest.isSuccess) {
            return timezonesRequest?.data?.data
        }
        return []
    }, [timezonesRequest.isSuccess, timezonesRequest.data]);


    useEffect(() => {
        if (!open)
            return
        if (editingCompany) {
            setName(editingCompany.name)
            setDescription(editingCompany.description)
            setTimezone(editingCompany.timezone)
        }
        else {
            setName('')
            setDescription('')
        }
    }, [editingCompany, open])

    const handleSave = () => {
        setDisabledButtons(true)
        const updatedCompany = { ...editingCompany, name, description, timezone }
        DmsService.updateCompany(updatedCompany).then((response) => {
            if (response.data.isSuccessful) {
                AlertToastr.showAlert(translation.notificationsCompanyUpdated)
                setEditingCompany(updatedCompany)
                if (companies && companies.length) {
                    const company = companies.find(x => x.id === updatedCompany.id)
                    company.name = updatedCompany.name
                    setCompanies([...companies])
                }
            }
            handleClose()
            setErrors({})
            setDisabledButtons(false)
        })
            .catch((error) => {
                if (error.response.data) {
                    const resErrors = normalizeErrorResponse(error.response.data)
                    if (resErrors.errors)
                        setErrors(resErrors.errors)
                    setDisabledButtons(false)
                    if (resErrors && resErrors.message)
                        AlertToastr.showErrorAlert(resErrors.message)
                }
                if (error.response.data.errors)
                    error.response.data.errors.filter(x => x.PropertyName === '').map(x => {
                        AlertToastr.showErrorAlert(x.ErrorMessage)
                        return x
                    })
            })
    }

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={e => {
                    handleClose()
                    setErrors({})
                }}
                className='respModal scrollContentModal autoHeight'
            >
                <div className="formContentHolder">
                    <div className="modalTitleHolder">
                        <h1 className='modalTitle text-center'>{translation.saveCompanyModalEditCompanyLabel}</h1>
                    </div>
                    <div className="formContent">
                        <div className="formContentBlock modalFieldLabelSettings">
                            <Tooltip disableFocusListener disableTouchListener title={errors && errors.name ? errors.name.join('\n') : ''}>
                                <div>
                                    <TextField
                                        value={name}
                                        onChange={e => {
                                            if (errors.name)
                                                errors.name = null
                                            setName(e.target.value)
                                        }}
                                        error={errors && errors.name && errors.name.length > 0}
                                        id="source myCompanyEditMyCompanyModalName"
                                        label={translation.saveCompanyModalNameLabel}
                                        variant="outlined"
                                        margin='none'
                                        fullWidth
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="formContent">
                        <div className="formContentBlock modalFieldLabelSettings">
                            <Tooltip disableFocusListener disableTouchListener title={errors && errors.description ? errors.description.join('\n') : ''}>
                                <div>
                                    <TextField
                                        value={description}
                                        onChange={e => {
                                            if (errors.description)
                                                errors.description = null
                                            setDescription(e.target.value)
                                        }}
                                        error={errors && errors.description && errors.description.length > 0}
                                        id="source myCompanyEditMyCompanyModalDescription"
                                        label={translation.saveCompanyModalDescriptionLabel}
                                        variant="outlined"
                                        margin='none'
                                        fullWidth
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    </div>

                    <div className="formContent">
                        <div className="formContentBlock modalFieldLabelSettings">
                            <Tooltip disableFocusListener disableTouchListener title={errors && errors.description ? errors.description.join('\n') : ''}>
                                <div>
                                    {
                                        timezoneOptions.length && (
                                            <TextField
                                                value={timezone}
                                                onChange={e => {
                                                    if (errors.timezone)
                                                        errors.timezone = null
                                                    setTimezone(e.target.value )
                                                }}
                                                label={translation.timezoneLabel}
                                                error={errors && errors.timezone && errors.timezone.length > 0}
                                                id="timezone myCompanyEditMyCompanyModalChangeTimeZone"
                                                variant="outlined"
                                                margin='normal'
                                                select
                                                fullWidth
                                            >
                                                { timezoneOptions.map((option) => {
                                                    return <MenuItem key={option.id} value={option.id} id="myCompanyEditMyCompanyModalChooseTimeZone">
                                                        {`(${option.offset}) ${option.id}`}
                                                    </MenuItem>
                                                })}
                                            </TextField>
                                        )
                                    }

                                </div>
                            </Tooltip>
                        </div>
                    </div>


                    <div className="btnHolder end fixedWidthBtn">
                            <Button
                                disabled={!!disableButtons}
                                variant="outlined"
                                fullWidth
                                onClick={e => {
                                    handleClose()
                                    setErrors({})
                                }}
                            >
                                {translation.cancelButtonLabel}
                            </Button>
                            <Button
                                disabled={!!disableButtons}
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleSave}
                            >
                                {translation.saveButtonLabel}
                            </Button>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

const mapStateToProps = ({ mms, dashboard }) => ({
    editingCompany: mms.editingCompany,
    companies: dashboard.companies
})

const mapDispatchToProps = {
    setEditingCompany,
    setCompanies
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveCompanyModal)
