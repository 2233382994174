import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { Button } from "@material-ui/core";
import useTranslation from 'Hooks/useTranslation'

const DeleteItemModal = ({ isOpen, isOpenManager, onDeleteModalClose, message, loading, onCancel, confirmButtonLabel }) => {
    const translation = useTranslation();
    const changeButtonLabel = confirmButtonLabel ? confirmButtonLabel : translation.deleteButtonLabel;
    return (
        <Dialog open={isOpen} onKeyDown={(e) => {
            if (e.key === 'Enter') {
                e.preventDefault()
                isOpenManager(false);
                onDeleteModalClose();
            }
        }} className='respModal  autoHeight'>
            <div className="modalTitleHolder mb16">
                <h1 className='modalTitle'>{translation.removeComponentModalPermissionLabel}</h1>
            </div>
            <p className='deleteItemLabel'>{message}</p>
            <div className="btnHolder end fixedWidthBtn">
                <Button
                    onClick={() => {
                        isOpenManager(false)
                        if (onCancel) onCancel()
                    }}
                    variant="outlined"
                    id="modalCancelBtn"
                    fullWidth
                >{translation.cancelButtonLabel}</Button>
                <Button
                    disabled={loading}
                    onClick={() => { isOpenManager(false); onDeleteModalClose() }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    id="modalSaveRemoveBtn"
                >{changeButtonLabel}</Button>
            </div>
        </Dialog>
    )
}

export default DeleteItemModal
