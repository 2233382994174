import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Dialog,
  TextField,
  FormLabel,
  MenuItem,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import { setRunComponentsUpdate } from "../../../../Store/modules/mms/actions";
import { connect } from "react-redux";
import useTranslation from "Hooks/useTranslation";
import MmsService from "Services/MMS/mms-api.service";
import AlertToastr from "utils/alert";
import { useAuth } from "../../../../Contexts/AuthProvider";
import { apiMmsBaseUrl } from "config";
import Api from "../../../../Services/Api.service";
import { normalizeErrorResponse } from "utils/form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAppSettings } from "Contexts/AppSettingsProvider";
import enGB from "date-fns/locale/en-GB";
import ru from "date-fns/locale/ru";
import { format, isBefore } from "date-fns";
import EventIcon from "@material-ui/icons/Event";
import { Autocomplete } from '@material-ui/lab';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const ReplaceComponentModal = ({
  isOpen,
  handleClose,
  departmentId,
  addedComponent,
  componentIds,
  onSuccessReplace,
  isOpenManager,
  setExpandedIds,
}) => {
  const emptyComponentOption = {
    id: "empty",
    name: "Select component to be installed",
  };
  const enterComponentNameOption = { id: "enter", name: "New component" };
  const emptyComponentStateOption = { id: "empty", name: "Select state" };
  const translation = useTranslation();
  const { currentCompany } = useAuth();
  const { currentUser } = useAuth();
  const { appLanguage } = useAppSettings();
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false)
  const isSuperUser = useMemo(() => currentUser.isSuperuser(), [currentUser]);

  const [isEnteringComponentName, setIsEnteringComponentName] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [selectedComponent, setSelectedComponent] = useState(
    emptyComponentOption.id
  );
  const [selectedDateTime, setSelectedDateTime] = useState(new Date());
  const [selectedInputDateTime, setSelectedInputDateTime] = useState(format(new Date(), 'MM/dd/yyyy, HH:mm'));
  const [replaceWorkhours, setReplaceWorkhours] = useState(
    addedComponent[0]?.workhoursTotalCalculated
  );
  const [selectedState, setSelectedState] = useState("disposed");
  const [states, setStates] = useState([]);
  const [components, setComponents] = useState([]);
  const [newAddedComponentName, setNewComponentName] = useState(null);

  const [textFieldKey, setTextFieldKey] = useState(0);
  const [errors, setErrors] = useState({});
  const [disableButtons, setDisabledButtons] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [isDefaultDateSet, setIsDefaultDateSet] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [selectedAutocompleteComponent, setSelectedAutocompleteComponent] = useState(
    emptyComponentOption.id
  );

  useEffect(() => {
    if (addedComponent[0]?.name && searchTerm !== addedComponent[0]?.name) {
      setSearchTerm(addedComponent[0]?.name);
      setReplaceWorkhours(addedComponent[0]?.workhoursTotalCalculated);
    }
  }, [addedComponent, searchTerm, setSearchTerm, setReplaceWorkhours]);

  useEffect(() => {
    registerLocale("English", enGB);
    registerLocale("Russian", ru);

    function handleResize() {
      setIsMobile(window.innerWidth <= 576);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    async function fetchComponentStates() {
      if (currentCompany?.id) {
        const componentStatesRequest = await Api.get(
          `${apiMmsBaseUrl}/options/component-states`,
          {
            headers: isSuperUser ? { CompanyId: currentCompany.id } : {},
          }
        );
        if (componentStatesRequest.data) {
          const filteredStates = componentStatesRequest.data.filter(
            (state) => state.componentState !== "removed"
          );
          setStates(filteredStates);
          setSelectedState("disposed");
        }
      } else {
        const componentStatesRequest = await Api.get(
          `${apiMmsBaseUrl}/options/component-states`
        );
        if (componentStatesRequest.data) {
          setStates(componentStatesRequest.data);
        }
      }
    }
    fetchComponentStates();
  }, [currentCompany?.id, isSuperUser]);

  const fetchComponentsWithState = useCallback(async () => {
    if (currentCompany?.id) {
      try {
        const headers = isSuperUser ? { CompanyId: currentCompany?.id } : {};
        const stateListQuery = await Api.get(
          `${apiMmsBaseUrl}/components/unassigned/state/fieldReady`,
          { headers }
        );

        if (stateListQuery.data) {
          setComponents(stateListQuery.data);
          // if (addedComponent && addedComponent[0] && addedComponent[0].name) {
          //   setComponents(stateListQuery?.data);
          // }
        }
      } catch (error) {
        console.error("Error fetching components:", error);
      }
    } else {
      try {
        const stateListQuery = await Api.get(
          `${apiMmsBaseUrl}/components/unassigned/state/fieldReady`
        );

        if (stateListQuery.data) {
          setComponents(stateListQuery.data);
          // if (addedComponent && addedComponent[0] && addedComponent[0].name) {
          //   setComponents(stateListQuery.data);
          // }
        }
      } catch (error) {
        console.error("Error fetching components:", error);
      }
    }
  }, [currentCompany?.id, isSuperUser]);

  useEffect(() => {
    fetchComponentsWithState();
  }, [currentCompany?.id, fetchComponentsWithState, isSuperUser]);

  const handleNewComponentChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === addedComponent[0]?.name) {
      setNewComponentName(addedComponent[0]?.name);
    } else {
      setNewComponentName(selectedValue);
    }
    setSelectedComponent(null);
  };

  const handleSerialNumberChange = (event) => {
    const value = event.target.value;
    setSerialNumber(value);
  };

  const handleMoveToComponentChange = (event) => {
    setErrors({});
    const value = event.target.value;
    setSelectedState(value);
    setTextFieldKey((prevKey) => prevKey + 1);
  };

  const handleWorkhoursChange = (value) => {
    setErrors({});
    setReplaceWorkhours(value);
  };

  const handleDateTimeChange = (date) => {
    const defaultHours = 23;
    const defaultMinutes = 59;

    if (isBefore(date, new Date())) {
      if (!isDefaultDateSet) {
        setIsDefaultDateSet(true);
        setSelectedDateTime(
          new Date(date.setHours(defaultHours, defaultMinutes))
        );
        setSelectedInputDateTime(format(new Date(date.setHours(defaultHours, defaultMinutes)), 'MM/dd/yyyy, HH:mm'))
      } else {
        // setSelectedInputDateTime(format(date, "MM/dd/yyyy, HH:mm"));
        // setSelectedDateTime(format(date, "yyyy-MM-dd'T'HH:mm:ss"))
        setSelectedInputDateTime(format(new Date(date), "MM/dd/yyyy, HH:mm"));
        setSelectedDateTime(new Date(date))
      }
    } else {
      setIsDefaultDateSet(false);
      setSelectedInputDateTime(format(date, "MM/dd/yyyy, HH:mm"));
      setSelectedDateTime(date)
    }
  };

  const handleInputDateTimeChange = (date) => {
    setSelectedInputDateTime(date)
    setSelectedDateTime(new Date(date))
  };

  const handleOpenDatePicker = () => {
    setOpenDatePicker(true);
  };

  const handleCloseDatePicker = () => {
    setOpenDatePicker(false);
  };

  const handleSaveButtonClick = () => {
    const oldComponentId = componentIds[0];
    const newComponentId =
    selectedAutocompleteComponent === "empty" ? null : selectedAutocompleteComponent?.id;
    const oldComponentState = selectedState === "empty" ? null : selectedState;
    const newComponentSerial = serialNumber;
    const newComponentName =
      newAddedComponentName === "empty" ? null : newAddedComponentName;
    const note = "";
    const replaceDate = 
    isDefaultDateSet
      ? 
      // format(selectedDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
      selectedDateTime
      : selectedDateTime
      ;

    setLoading(true)

    MmsService.replaceComponent(
      oldComponentId,
      newComponentId,
      oldComponentState,
      departmentId,
      newComponentSerial,
      note,
      newComponentName,
      replaceWorkhours,
      replaceDate
    )
      .then((response) => {
        if (response.data.isSuccessful) {
          setSelectedComponent(emptyComponentOption.id);
          setSelectedAutocompleteComponent(emptyComponentOption.id);
          setNewComponentName(null);
          setIsDefaultDateSet(false);
          setSelectedDateTime(new Date());
          setSelectedInputDateTime(format(new Date(), 'MM/dd/yyyy, HH:mm'));
        }
        if (onSuccessReplace) onSuccessReplace();
        setExpandedIds(response.data.expandedIds);
        isOpenManager(false);
        setErrors({});
        setSelectedComponent(emptyComponentOption.id);
        setSelectedAutocompleteComponent(emptyComponentOption.id);
        setNewComponentName(null);
        setSelectedState("disposed");
        setIsEnteringComponentName(false);
        setSerialNumber("");
        setLoading(false)
        AlertToastr.showAlert(
          `Your component ${addedComponent[0]?.fullName} has been successfully replaced`
        );
        fetchComponentsWithState();
      })
      .catch((error) => {
        setLoading(false)
        if (!error || !error.response || !error.response.data) return;
        const resErrors = normalizeErrorResponse(error.response.data);
        if (resErrors.errors) setErrors(resErrors.errors);
        setDisabledButtons(false);
        if (resErrors && resErrors.message)
          AlertToastr.showErrorAlert(resErrors.message);

        if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        )
          error.response.data.errors
            .filter((x) => x.PropertyName === "")
            .map((x) => {
              AlertToastr.showErrorAlert(x.ErrorMessage);
              return x;
            });
      });
  };

  const handleCloseModal = () => {
    setIsEnteringComponentName(false);
    setSelectedComponent(emptyComponentOption.id);
    setSelectedState(emptyComponentStateOption.id);
    setSerialNumber("");
    handleClose();
    setSelectedState("disposed");
    setNewComponentName(null);
    setErrors({});
    setReplaceWorkhours(addedComponent[0]?.workhoursTotalCalculated);
    setSelectedDateTime(new Date());
    setSelectedInputDateTime(format(new Date(), 'MM/dd/yyyy, HH:mm'));
    setIsDefaultDateSet(false);
    setOpenDatePicker(false)
    setSelectedAutocompleteComponent(null);
  };

  const handleAutocompleteChange = (event, newValue) => {
    setErrors({});
    if (newValue) {
      if (newValue.id === enterComponentNameOption.id) {
        setIsEnteringComponentName(true);
        setSelectedAutocompleteComponent(null);
        setNewComponentName(addedComponent[0]?.name);
        setSelectedComponent(emptyComponentOption.id);
       } else {
         setIsEnteringComponentName(false);
         setSelectedAutocompleteComponent(newValue);
         setSelectedComponent(newValue);
         setSerialNumber(newValue?.uId);
         setIsEnteringComponentName(false);
         setSelectedComponent(emptyComponentOption.id);
         setNewComponentName(null);
      }
    }
  };

  const handleAutocompleteInputChange = (event, value) => {
    const newValue = value.replace(/\s*\([^)]*\)\s*/, '');
    if (event) {
      const selectedValue = event.target.value;
      setNewComponentName(selectedValue);
    } else if (value) {
      setNewComponentName(newValue);
    }
  };

  const handleClearNewComponentName = () => {
    setNewComponentName(null);
    setSelectedComponent(null);
    setIsEnteringComponentName(false);
  };


  return (
    <Dialog
      open={isOpen}
      onClose={(e) => {
        handleCloseModal();
        setErrors({});
      }}
      className="respModal autoHeight"
      style={{ width: "448px", marginLeft: "auto", marginRight: "auto" }}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          handleSaveButtonClick();
        }
        if (e.key === "Escape") {
          handleCloseModal();
        }
      }}
    >
      <div className="mb16">
        <h1 className="workhoursUpdateModalTitle">
          {translation.replaceComponentModalLabel}
        </h1>
      </div>
      <div className="mb6">
        <h2 className="workhoursUpdateJobTitle">
          {addedComponent[0]?.fullName}
        </h2>
      </div>
      <div>
        <FormLabel component="legend" className="mb5">
          With:
        </FormLabel>
        <div className="">
        {isEnteringComponentName ? (
          <TextField
            id="replaceModalNewSelectComponent"
            variant="outlined"
            margin="normal"
            fullWidth
            className="mb16"
            autoFocus
            label={translation.replaceComponentModalComponentFieldLabel}
            onChange={handleNewComponentChange}
            value={newAddedComponentName === null ? addedComponent[0]?.name : newAddedComponentName}
            onBlur={() => {
              if (
                newAddedComponentName === null ||
                newAddedComponentName === addedComponent[0]?.name
              ) {
                setNewComponentName(addedComponent[0]?.name);
                setSelectedAutocompleteComponent(null);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className="replaceModalNewComponentBtn"
                    edge="end"
                    onClick={handleClearNewComponentName}
                    id="replaceModalNewSelectComponentCloseBtn"
                  >
                    <CloseIcon className="replaceModalNewComponentIcon"/>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <Autocomplete
            id="replaceModalSelectComponent"
            className='autocompletePartActivity'
            options={[
              emptyComponentOption,
              enterComponentNameOption,
              ...components,
            ]}
            getOptionLabel={(option) => option.uId ? `${option.name} (${option.uId})` : option.name}
            onChange={handleAutocompleteChange}
            inputValue={newAddedComponentName || ""}
            onInputChange={handleAutocompleteInputChange}
            value={selectedAutocompleteComponent}
            renderInput={(params) => (
              <TextField
                {...params}
                id="replaceModalNewSelectComponentChoose"
                label="Select component to be installed"
                variant="outlined"
                margin="normal"
                fullWidth
                className="mb16"
              />
            )}
          />
        )}
        </div>
        
      </div>
      {isEnteringComponentName && (
        <div className="mb16">
          <div>
            <TextField
              id="replaceModalEnterSerialNumber"
              variant="outlined"
              margin="normal"
              fullWidth
              className="mb5"
              placeholder="Enter serial number"
              label="Serial number"
              value={serialNumber}
              onChange={handleSerialNumberChange}
            />
          </div>
        </div>
      )}
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="mb16"
      >
        <div style={{ width: "48%" }}>
          <FormLabel component="legend" className="mb5">
            Replaced at:
          </FormLabel>
          <TextField
            id="replaceModalReplaceAt"
            value={replaceWorkhours}
            error={
              errors &&
              errors.workhoursTotalCalculated &&
              errors.workhoursTotalCalculated.length > 0
            }
            type="number"
            variant="outlined"
            margin="normal"
            // label={translation.replaceComponentModalComponentWorkhoursLabel}
            onChange={(e) => handleWorkhoursChange(e.target.value)}
            fullWidth
          />
        </div>

        <div style={{ width: "48%" }}>
          <FormLabel component="legend" style={{ marginBottom: "5px" }}>
            Replaced on:
          </FormLabel>
          <TextField
            id="replaceModalReplaceOn"
            onChange={(event) => handleInputDateTimeChange(event.target.value)}
            // className="formControlDatePicker"
            InputProps={{
              endAdornment: (
                <EventIcon
                  onClick={handleOpenDatePicker}
                  className="datePickerCalendarIcon"
                />
              ),
            }}
            variant="outlined"
            margin="normal"
            error={
              errors &&
              errors.selectedDateTime &&
              errors.selectedDateTime.length > 0
            }
            value={
              // selectedDateTime
              //   ? format(selectedDateTime, 'MM/dd/yyyy, HH:mm')
              //   : null
              selectedInputDateTime
            }
            locale={appLanguage}                                 
            fullWidth
          />

          {openDatePicker && (
            <div className="replaceModalDatepickerContainer">
              <DatePicker
                inline
                onClickOutside={() => setOpenDatePicker(false)}
                onClose={handleCloseDatePicker}
                id="replaceModalReplaceOn"
                onChange={handleDateTimeChange}
                maxDate={new Date("12/31/2999")}
                showTimeSelect
                timeIntervals={1}
                error={
                  errors &&
                  errors.selectedDateTime &&
                  errors.selectedDateTime.length > 0
                }
                selected={selectedDateTime}
                // withPortal={isMobile}
                locale={appLanguage}
                dateFormat="M/d/yyyy, HH:mm"
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <FormLabel component="legend" className="mb5">
          Move replaced component to:
        </FormLabel>
        <div className="">
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={
              errors && errors.oldComponentState
                ? errors.oldComponentState.join("\n")
                : ""
            }
          >
            <TextField
              type="number"
              variant="outlined"
              margin="normal"
              fullWidth
              select
              className="mb16"
              value={selectedState}
              onChange={handleMoveToComponentChange}
              error={
                errors &&
                errors.oldComponentState &&
                errors.oldComponentState.length > 0
              }
              key={textFieldKey}
              label={translation.replaceComponentModalStateFieldLabel}
              id="replaceModalChangeComponentState"
            >
              <MenuItem value={emptyComponentStateOption.id}>
                {emptyComponentStateOption.name}
              </MenuItem>
              {states.map((state, index) => (
                <MenuItem
                  id="replaceModalChooseComponentState"
                  key={index}
                  value={state.componentState}
                  onClick={() => setSelectedState(state.componentState)}
                  className=""
                >
                  {state.name}
                </MenuItem>
              ))}
            </TextField>
          </Tooltip>
        </div>
      </div>
      <div className="btnHolder end fixedWidthBtn">
        <Button
          variant="outlined"
          id="replaceModalCancelBtn"
          fullWidth
          onClick={handleCloseModal}
        >
          {translation.cancelButtonLabel}
        </Button>       
        <div className="part">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            id="replaceModalSaveBtn"
            style={{ marginBottom: "0" }}
            onClick={handleSaveButtonClick}
            disabled={!!disableButtons || loading}
          >
            Save
          </Button>
             {loading && <CircularProgress size={24} />}
         </div>
      </div>
    </Dialog>
  );
};

const mapDispatchToProps = {
  setRunComponentsUpdate,
};

export default connect(null, mapDispatchToProps)(ReplaceComponentModal);