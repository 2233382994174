import React, { useState, Fragment } from 'react'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog"
import useTranslation from 'Hooks/useTranslation'

const ExportReportModal = ({ open, handleClose, onExport }) => {
    const translation = useTranslation();

    const [name, setName] = useState('')

    return (
        <Fragment>
            <Dialog
                open={open}
                onClose={e => {
                    handleClose()
                }}
                className='respModal scrollContentModal autoHeight'
            >
                <div className="formContentHolder">
                    <div className="modalTitleHolder">
                        <h1 className='modalTitle text-center'>Export Report</h1>
                    </div>
                    <div className="formContent">
                        <div className="formContentBlock">
                            <TextField
                                value={name}
                                onChange={e => {
                                    setName(e.target.value)
                                }}
                                id="source"
                                label="Name"
                                variant="outlined"
                                margin='none'
                                fullWidth
                            />
                        </div>
                    </div>
                    <div className="btnHolder end fixedWidthBtn">
                        <div className="part">
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={e => {
                                    handleClose()
                                }}
                            >
                                {translation.cancelButtonLabel}
                            </Button>
                        </div>
                        <div className="part">
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={e => onExport(name)}
                            >
                                {translation.exportButtonLabel}
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default ExportReportModal
