import React, { useMemo, useState } from 'react';
import useItemTypes from '../../../Hooks/useItemTypes';
import AddBoxMenu from '../../../Components/AddBoxMenu/AddBoxMenu.component';
import OptionsMenu from '../../../Components/OptionsMenu';
import { useAuth } from '../../../Contexts/AuthProvider';
import { useBoxes } from '../../../Contexts/BoxContext';
import { userHasPermission } from '../../../helpers';
import UnitJobDateRangeGlobalFilters from '../../../Components/filters/UnitJobDateRangeGlobalFilters';
import DownloadFileButton from '../../../Components/DownloadFileButton';
import useTranslation from 'Hooks/useTranslation';
import { Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import MapComponentModal from 'Components/MapComponentModal';

const LocationGroupHeader = props => {

    const {
        onAddBox,
        title,
        onLoadTemplate,
        onSaveTemplate,
        onExportCsv,
        onChangeUnit,
        unitsRequest,
        handleChangeUnit,
        showMapBtn,
        gpsData
    } = props;

    const { currentUser } = useAuth();
    const {
        locationBoxes: unitBoxes,
        currentLocationUnit: currentUnit,
        currentLocationUnitModel: currentUnitModel,
        isMainGroup,
        locationBoxesFilters: unitBoxesFilters,
        changeLocationGlobalFilters: changeUnitGlobalFilters,
        refreshBoxes,
        dataExportLocationsRequest
    } = useBoxes();
    const { data: itemTypes } = useItemTypes('units');
    const translation = useTranslation();
    const [isMapModalOpen, setIsMapModalOpen] = useState(false);

    const allowManageTemplate = useMemo(() => userHasPermission(currentUser, 'manage_templates'), [currentUser]);

    const renderOptionsButton = () => {
        if (!isMainGroup) return null;

        return (
            <OptionsMenu
                refreshDisabled={!unitBoxes.length}
                onRefresh={() => refreshBoxes('locations')}
                isCurrentJobSet={!!currentUnit}
                allowManageTemplate={allowManageTemplate}
                disableSaveTemplate={!currentUnit && unitBoxes.length === 0}
                onLoadTemplate={onLoadTemplate}
                onSaveTemplate={onSaveTemplate}
                onExportCsv={onExportCsv}
                onChangeUnit={onChangeUnit}
                changeUnitDisabled={!currentUnit}
                exportCsvLabel={translation.exportLocationToCsvLabel}
            />
        );
    };

    const renderGlobalFilters = () => {
        if (!isMainGroup) return null;
        // if (!currentUnitModel) return null;


        return (
            <UnitJobDateRangeGlobalFilters
                tabType='locations'
                unitUid={currentUnitModel?.uId}
                inline
                onChange={changeUnitGlobalFilters}
                initData={unitBoxesFilters}
            />
        );
    };

    return (
      <>
        <div className="pageBtnSection">
          <div className="part">
            <div className="btnGroup">
              <div className="item">
                <AddBoxMenu itemTypes={itemTypes} onAddBox={onAddBox} />
              </div>
              <div className="item">{renderOptionsButton()}</div>
              <div className="item">
                <DownloadFileButton
                  getExportRequest={dataExportLocationsRequest}
                  tabType="locations" />
              </div>
            </div>
          </div>
          <div className="part centered centeredMobile">
            <div className="flex">
              <Button
                onClick={() => {
                  onChangeUnit();
                } }
                className="asideNavItem"
                fullWidth
                endIcon={<ArrowDropDownIcon />}
                id="asideMmsMenuChangeDepartmentBtn"
              >
                <span className="hasMaxWidth titleLimitSymbols">
                  {currentUnitModel ? currentUnitModel.name : "Select Location"}
                </span>
              </Button>
              {showMapBtn && currentUnitModel?.uId ? (
                <Button
                  onClick={() => {
                    setIsMapModalOpen(true);
                  } }
                  className="asideNavItem"
                  endIcon={<MyLocationIcon style={{ marginLeft: "-40px" }} />}
                >
                  {/* <span className="hasMaxWidth">View on Map</span> */}
                </Button>
              ) : null}
            </div>
          </div>
          <div className="part end">{renderGlobalFilters()}</div>
        </div>
        <MapComponentModal currentUnitModel={currentUnitModel} isOpen={isMapModalOpen} onCancel={() => setIsMapModalOpen(false)} section="locations" gpsData={gpsData}/>
      </>
    );
};

export default LocationGroupHeader;
