import React, { useState, useEffect, useMemo } from 'react'
import TextField from '@material-ui/core/TextField'
import MmsService from 'Services/MMS/mms-api.service'
import { MenuItem } from '@material-ui/core'
import AlertToastr from 'utils/alert'
import useTranslation from 'Hooks/useTranslation'

const AssignPersonButton = (props) => {

  const {
    employeeId,
    activityId,
    employeesOptions,
    department,
    fetchActivities
  } = props

  const translation = useTranslation()
  const emptyAssagneeOption = useMemo(() => {
    return { id: '00000000-0000-0000-0000-000000000000', name: translation.assignPersonModalSelectAssigneeLabel }
  }, [translation])

  const [selectedEmployeeId, setSelectedEmployeeId] = useState('')
  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    if (employeeId)
      setSelectedEmployeeId(employeeId)
    else
      setSelectedEmployeeId(emptyAssagneeOption.id)
  }, [employeeId, emptyAssagneeOption.id])

  const onSave = (e) => {
    setProcessing(true)
    e.persist()
    const value = e.target.value
    setSelectedEmployeeId(value)

    MmsService.assingPersonToActivity(activityId, value === 'empty' ? null : value)
      .then((response) => {
        if (response.data.isSuccessful) {
          AlertToastr.showAlert(translation.notificationsEmployeeAssigned)
          // handleSave(value);
        }
        setProcessing(false)
        fetchActivities().then()
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          const errData = error.response.data
          let errMessage = errData['Message']

          if (errData && errData.errors && errData.errors[0] && errData.errors[0]['ErrorMessage']) {
            errMessage = errData.errors[0]['ErrorMessage']
          }

          AlertToastr.showErrorAlert(errMessage)
        }
        setProcessing(false)
      })
  }

  return (

    <TextField
      style={{ width: '100%' }}
      disabled={processing}
      value={selectedEmployeeId}
      onChange={onSave}
      id="employees"
      variant="outlined"
      // margin="normal"
      select
      fullWidth
    >
      <MenuItem
        key={emptyAssagneeOption.id}
        id="activitiesTblChangeAssignee"
        className={`filterMenuItem ${employeeId ? '' : ''}`}
        onChange={onSave}
        value={emptyAssagneeOption.id}>
        {emptyAssagneeOption.name}
      </MenuItem>

      {department
        ? <p className="filterMenuItemDisabled">{translation.saveDepartmentModalFromDepartmentLabel}</p>
        : <p style={{ display: 'none' }}></p>}

      {employeesOptions.fromDepartment.map((employee) => (
        <MenuItem
          key={employee.id}
          id="activitiesTblChooseAssigneeFromDep"
          className="filterMenuItem"
          value={employee.id}
        >
          {employee.name}
        </MenuItem>
      ))}

      {department
        ? <p className="filterMenuItemDisabled">{translation.saveDepartmentModalOthersLabel}</p>
        : <p style={{ display: 'none' }}></p>}

      {employeesOptions.other.map((employee) => (
        <MenuItem
          key={employee.id}
          id="activitiesTblChooseAssigneeOthers"
          className="filterMenuItem"
          value={employee.id}
        >
          {employee.name}
        </MenuItem>
      ))}

      {/*{departmentEmployees && departmentEmployees.map((option) => {*/}
      {/*    return <MenuItem key={option.id} value={option.id}>*/}
      {/*        {option.name}*/}
      {/*    </MenuItem>;*/}
      {/*})}*/}
    </TextField>
  )
}

export default AssignPersonButton
